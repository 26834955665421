import { useMap } from 'react-map-gl'
import { useRouter } from 'next/router'
import { useUpdateEffect } from 'react-use'
import { useState, useCallback, useMemo, createContext, useContext, ReactNode } from 'react'
import {
  useMapFilters,
  useMapLocationID,
  useMarkers,
} from '@electro/consumersite/src/components/Map/hooks'
import {
  MapParamsEnum,
  MapFiltersEnum,
  MapFiltersType,
} from '@electro/consumersite/src/components/Map/types'
import {
  getCameraAnimationOptions,
  PlaceDetails,
} from '@electro/consumersite/src/components/Map/helpers'
// eslint-disable-next-line import/no-unresolved
import { mapFiltersOptions } from '@electro/consumersite/generated/mapBuildData'
import { countryBoundingBoxes } from '@electro/consumersite/generated/mapStaticData'

const {
  POSITION,
  SPEED,
  PITCH,
  BEARING,

  ELECTROVERSE_COMPATIBLE,
  OPERATORS,
  SOCKET_TYPES,
  CHARGE_POINT_SPEEDS,
  MIN_CHARGE_POINTS,
  ACCESS,
  LAT,
  LNG,
  ZOOM,
  PITCH_FULL,
  BEARING_FULL,
  EJN_ONLY_FULL,
} = MapParamsEnum

interface State {}

interface Handlers {}

type UseMapParams = [state: State, handlers: Handlers]

const UseMapParamsContext = createContext<UseMapParams>(null)

function useMapParamsProvider(): UseMapParams {
  const { baseMap } = useMap()
  const { locale, asPath } = useRouter()
  const { handleSelectLocation } = useMarkers()
  const [, { applyInitialFilters }] = useMapFilters()
  const { getMapCentreFromLocationID } = useMapLocationID()
  const { bbox } = countryBoundingBoxes[locale] as PlaceDetails

  const [mapBoundsDefined, setMapBoundsDefined] = useState<boolean>(false)

  /** Convert URL search params into a usable format for the Map filters */
  const formatFiltersParams = useCallback((params: URLSearchParams): Partial<MapFiltersType> => {
    const filters = {}

    // Electroverse Only Toggle
    if (params.has(ELECTROVERSE_COMPATIBLE)) {
      filters[MapFiltersEnum.ELECTROVERSE_COMPATIBLE] = JSON.parse(
        params.get(ELECTROVERSE_COMPATIBLE),
      )
    } else if (params.has(EJN_ONLY_FULL)) {
      filters[MapFiltersEnum.ELECTROVERSE_COMPATIBLE] = JSON.parse(params.get(EJN_ONLY_FULL))
    }

    // Operators List
    if (params.has(OPERATORS)) {
      const operatorsIDs = params.get(OPERATORS).split(',')
      filters[MapFiltersEnum.OPERATORS] = mapFiltersOptions.operators.filter(({ pk }) =>
        operatorsIDs.some((id: string) => parseInt(id, 10) === pk),
      )
    }

    // Socket Types List
    if (params.has(SOCKET_TYPES)) {
      filters[MapFiltersEnum.SOCKET_TYPES] = params
        .get(SOCKET_TYPES)
        .split(',')
        .filter((socket) => mapFiltersOptions.connectors.includes(socket))
    }

    // Charge Speed Range Slider
    if (params.has(CHARGE_POINT_SPEEDS)) {
      filters[MapFiltersEnum.CHARGE_POINT_SPEEDS] = params
        .get(CHARGE_POINT_SPEEDS)
        .split(',')
        .map((speed: string) => parseInt(speed, 10))
        .splice(0, 2)
    }

    // Minimum Charge Points Slider
    if (params.has(MIN_CHARGE_POINTS)) {
      filters[MapFiltersEnum.MIN_CHARGE_POINTS] = parseInt(params.get(MIN_CHARGE_POINTS), 10)
    }

    // Access List
    if (params.has(ACCESS)) {
      filters[MapFiltersEnum.ACCESS] = params
        .get(ACCESS)
        .split(',')
        .filter((accessType) => mapFiltersOptions.capabilities.includes(accessType))
    }

    return filters as Partial<MapFiltersType>
  }, [])

  /** Retrieve the URL search params and create an object to be consumed by initial functions across the map */
  const mapURLParams = useMemo(() => {
    if (!asPath) return {}
    const { pathname, search } = new URL(asPath, 'https://electroverse.octopus.energy')
    const searchParams = new URLSearchParams(search.replaceAll('%20', ' '))

    // Position param - split into lat, lng, zoom
    const pos = searchParams.get(POSITION)?.split(',').map(parseFloat)
    const position = {
      lat: pos?.[0] ?? parseFloat(searchParams.get(LAT)),
      lng: pos?.[1] ?? parseFloat(searchParams.get(LNG)),
      zoom: pos?.[2] ?? parseFloat(searchParams.get(ZOOM)),
    }

    // Charging Location ID
    const pathnameArray = pathname.split('/')
    const locationID = pathnameArray[pathnameArray.indexOf('location') + 1]

    return {
      mapbox: {
        position,
        speed: parseFloat(searchParams.get(SPEED)),
        pitch: parseFloat(searchParams.get(PITCH) ?? searchParams.get(PITCH_FULL)),
        bearing: parseFloat(searchParams.get(BEARING) ?? searchParams.get(BEARING_FULL)),
      },
      filters: formatFiltersParams(searchParams),
      locationID,
    }
  }, [asPath, formatFiltersParams])

  /** Applies the URL search params to filters and mapbox camera animations on load */
  const applyURLParams = useCallback(async () => {
    const { mapbox, filters, locationID } = mapURLParams
    const { position, speed, pitch, bearing } = mapbox
    const { lng, lat, zoom } = position

    applyInitialFilters(filters)

    const flyToOptions = {
      ...(bearing ? { bearing } : {}),
      ...(pitch ? { pitch } : {}),
      ...(speed ? { speed } : {}),
      ...(zoom ? { zoom } : {}),
    }

    if (lat && lng) {
      // Fly to specific coordinates if defined in the URL
      baseMap.flyTo({
        ...getCameraAnimationOptions({ zoomLevel: 'country' }),
        ...(lat && lng ? { center: { lat, lng } } : {}),
        ...flyToOptions,
      })
    } else if (locationID) {
      // Fly to a charging location if defined in the URL
      const center = await getMapCentreFromLocationID(locationID)

      if (center.lat && center.lng) {
        baseMap.flyTo({ ...getCameraAnimationOptions(), ...flyToOptions, center })
      }
      handleSelectLocation({ location: { properties: { _id: locationID, is_ejn_location: true } } })
    } else {
      // Fly to country if a specific location is not defined in the URL
      baseMap.fitBounds(bbox, {
        ...getCameraAnimationOptions({ zoomLevel: 'country', type: 'fitBounds' }),
        ...flyToOptions,
      })
    }
  }, [
    mapURLParams,
    applyInitialFilters,
    baseMap,
    getMapCentreFromLocationID,
    handleSelectLocation,
    bbox,
  ])

  /** Ensures the URL search params are interpreted on map load only once */
  useUpdateEffect(() => {
    if (baseMap && !mapBoundsDefined) {
      setMapBoundsDefined(true)
      applyURLParams()
    }
  }, [baseMap, mapBoundsDefined, applyURLParams])

  const state = useMemo(() => ({}), [])

  const handlers = useMemo(() => ({}), [])

  return [state, handlers]
}

export const UseMapParamsProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const ctx = useMapParamsProvider()
  return <UseMapParamsContext.Provider value={ctx}>{children}</UseMapParamsContext.Provider>
}

export const useMapParams = (): UseMapParams => {
  const context = useContext(UseMapParamsContext)
  if (!context) throw new Error('UseMapParams() cannot be used outside of <UseMapParamsProvider/>')
  return context
}
