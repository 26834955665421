import { tw } from '@electro/shared/utils/tailwind-merge'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { SidebarPanelContainer } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarPanelContainer'
import { SidebarNavLabelsPanelContent } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels'

const styles = {
  container: tw(
    'text-xl -mt-1.5',
    '[&_p]:border-b [&_p]:border-secondary [&_p]:overflow-hidden [&_p]:m-0 [&_p]:h-11',
  ),
}

export const SidebarNavLabelsPanel = () => (
  <SidebarPanelContainer panelType={SidebarPanels.ICON_NAV_LABELS} className={styles.container}>
    <SidebarNavLabelsPanelContent />
  </SidebarPanelContainer>
)
