import {
  GhostRouteLayer,
  RouteLayer,
} from '@electro/consumersite/src/components/Map/components/MapLayers/layers'

export const RoutePlanLayer = () => (
  <>
    <GhostRouteLayer />
    <RouteLayer />
  </>
)
