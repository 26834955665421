import { AccountType } from '@electro/consumersite/generated/graphql'
import { isArray } from '@electro/shared/utils/typed'

export function isActiveAccountFleetDriver(
  accounts: AccountType[],
  activeAccountId: string,
): boolean {
  const activeAccount = accounts?.filter(({ id }) => activeAccountId === `${id}`)

  if (isArray(activeAccount)) {
    return activeAccount.some(
      ({ entityDomain, isFleetDriver }) => entityDomain === 'FLEETS' && isFleetDriver,
    )
  }

  return false
}
