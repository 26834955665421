/**
 * - Add steps as an array.
 * - range is 0 - array.length
 * - each step is rendered as a dot.
 * - map the value to the step to return a value from the array in the callback
 * - change color in renderThumb based on value. This will need to be passed as a prop for reuse.
 * - provide errors for min-max props if steps are passed.
 */

import { createContext, ReactNode, useContext, useState, useMemo, useEffect } from 'react'
import { Range, getTrackBackground } from 'react-range'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useMount } from '@electro/shared/hooks'
import theme from '@electro/shared/theme/electro'

export interface NonLinearSliderStep {
  value: number
  label?: string
  activeStepColor?: string
}

interface UseSlider {
  values: number[]
  handleSliderChange: (values: number[]) => void
  setSliderValues: (nextValue: number[]) => void
  settings: SliderSettings
  nonLinearSteps?: NonLinearSliderStep[]
  hasNonLinearSteps: boolean
  nonLinearStepCurrentValues: [number, number]
}

interface SliderChildrenRenderProps {
  values: number[]
  setSliderValues: (nextValue: number[]) => void
}

interface SliderProps {
  children: ReactNode | ReactNode[] | ((values: SliderChildrenRenderProps) => ReactNode)
  name: string
  onChange?: (values: number[]) => void
  initialValues: number[]
  max?: number
  min?: number
  nonLinearSteps?: NonLinearSliderStep[]
}

interface SliderSettings {
  sliderId: string
  max?: number
  min?: number
}

const SliderContext = createContext<UseSlider>(null)

const useSlider = ({
  initialValues = [50],
  name,
  max = 100,
  min = 0,
  onChange,
  nonLinearSteps = [],
}): UseSlider => {
  const [values, setValues] = useState<number[]>()

  /**
   * For now we only need to support one slider value.
   */
  function handleSliderChange(nextValues) {
    setValues(nextValues)
  }

  function setSliderValues(nextValues) {
    setValues(nextValues)
  }

  const hasNonLinearSteps = useMemo(() => nonLinearSteps?.length > 0, [nonLinearSteps])

  const nonLinearStepCurrentValues: [number, number] | null = useMemo(
    () =>
      nonLinearSteps
        ? [nonLinearSteps[values?.[0]]?.value, nonLinearSteps?.[values?.[1]]?.value]
        : null,
    [values, nonLinearSteps],
  )

  /**
   * We want to make sure our onChange callback is always up to date
   * with the current slider value.
   * If we have non linear steps map the values returned to the callback
   * to the array of values passed as props .
   */
  useEffect(() => {
    if (onChange && values) {
      if (hasNonLinearSteps) {
        onChange(nonLinearStepCurrentValues)
      } else {
        onChange(values)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useMount(() => {
    if (initialValues && hasNonLinearSteps) {
      if (initialValues.length !== 2) {
        throw new Error(
          'You must pass the tuple [number, number] to the initialValues prop when using nonLinearSteps',
        )
      }
      const initialValuesAsIndexes = initialValues.map((value) =>
        nonLinearSteps.findIndex((step) => step.value === value),
      )
      if (initialValuesAsIndexes.some((idx) => idx === -1)) {
        throw new Error(
          'non initial values must match a value in the array passed to nonLinearSteps',
        )
      }
      setValues(initialValuesAsIndexes)
    } else {
      setValues(initialValues)
    }
  })

  const settings = useMemo(
    () => ({
      sliderId: name,
      max: hasNonLinearSteps ? nonLinearSteps.length - 1 : max,
      min: hasNonLinearSteps ? 0 : min,
    }),
    [name, hasNonLinearSteps, nonLinearSteps, max, min],
  )

  return {
    values,
    handleSliderChange,
    setSliderValues,
    settings,
    hasNonLinearSteps,
    nonLinearSteps,
    nonLinearStepCurrentValues,
  }
}

function useSliderContext(): UseSlider {
  const context = useContext(SliderContext)
  if (!context) {
    throw new Error(`Slider compound components cannot be rendered outside the <Slider/> component`)
  }
  return context
}

const Rail = () => {
  const { values, handleSliderChange, settings, hasNonLinearSteps } = useSliderContext()
  const [railColors, setRailColors] = useState([])
  const [sliderValues, setSliderValues] = useState<number[]>(values)

  // The rail color is set based on whether the user is in dark mode and whether there are non-linear steps
  useEffect(() => {
    const railColor = theme.extend.colors.secondary.DEFAULT
    const railBgColor = theme.extend.colors.base.DEFAULT

    if (hasNonLinearSteps) {
      setRailColors([railBgColor, railColor, railBgColor])
    } else {
      setRailColors([railColor, railBgColor])
    }
  }, [hasNonLinearSteps])

  useEffect(() => {
    setSliderValues(
      values.map((num) => {
        if (num > settings.max) return settings.max
        if (num < settings.min) return settings.min
        return num
      }),
    )
  }, [values, settings.max, settings.min])

  return (
    <div className="relative p-2" data-testid={`${settings.sliderId}-slider`}>
      {values && (
        <Range
          step={1}
          min={settings.min}
          max={settings.max}
          values={sliderValues}
          onChange={handleSliderChange}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              className="w-full h-1.5 outline-none rounded-md"
              style={{
                background: getTrackBackground({
                  values,
                  colors: railColors,
                  min: settings.min,
                  max: settings.max,
                }),
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              className={tw(
                hasNonLinearSteps ? 'bg-transparent' : 'bg-secondary',
                'rounded-full h-4 w-4 justify-center items-center flex  outline-none z-20 ',
                'focus:ring-2 focus:shadow-md focus:ring-secondary focus:bg-secondary',
              )}
            />
          )}
        />
      )}
    </div>
  )
}

const NonLinearStepLabels = () => {
  const { nonLinearSteps, hasNonLinearSteps, nonLinearStepCurrentValues } = useSliderContext()
  if (!nonLinearStepCurrentValues) return null
  const [minCurrentRangeValue, maxCurrentRangeValue] = nonLinearStepCurrentValues
  const styles = {
    root: 'flex w-full justify-between absolute pointer-events-none',
    stepMarker: {
      root: 'text-2xs w-4 h-4 rounded-full relative text-white',
      inactive: 'bg-base',
      active: 'bg-secondary',
    },
  }
  return (
    hasNonLinearSteps && (
      <div className={styles.root} style={{ bottom: '3px' }}>
        {nonLinearSteps?.map((step) => (
          <div
            key={step.value}
            className={tw({
              [styles.stepMarker.root]: true,
              [styles.stepMarker.active]:
                step.value >= minCurrentRangeValue && step.value <= maxCurrentRangeValue, // in between max & min values
              [styles.stepMarker.inactive]:
                step.value < minCurrentRangeValue || step.value > maxCurrentRangeValue,
            })}
          >
            {step.activeStepColor &&
              [minCurrentRangeValue, maxCurrentRangeValue].some(
                (value) => value === step.value,
              ) && (
                <span
                  data-testid="active-dot"
                  // 50.5% fixes the dots being off-centre on some monitor resolutions. The cause is an odd number viewport height
                  className="w-2 h-2 z-10 absolute rounded-full top-1/2 left-1/2 -translate-x-[50.5%] -translate-y-[50.5%]"
                  style={{ backgroundColor: step.activeStepColor }}
                />
              )}
            <span className="text-white absolute -bottom-4 left-1/2 -translate-x-1/2">
              {step.label}
            </span>
          </div>
        ))}
      </div>
    )
  )
}

const Label = ({ children }) => {
  const { settings } = useSliderContext()
  return (
    <label htmlFor={settings.sliderId} className="block w-full mb-1 text-sm pl-2 -pb-1 text-white">
      {children}
    </label>
  )
}

const SliderProvider = ({ children, initialValues, name, max, min, onChange, nonLinearSteps }) => {
  const context = useSlider({ initialValues, name, max, min, onChange, nonLinearSteps })
  return <SliderContext.Provider value={context}>{children}</SliderContext.Provider>
}

const SliderRenderProps = ({ children }) => {
  const { values, setSliderValues, nonLinearStepCurrentValues, hasNonLinearSteps } =
    useSliderContext()
  if (!values) return null
  if (typeof children === 'object') return children
  return children({
    values: hasNonLinearSteps ? nonLinearStepCurrentValues : values,
    setSliderValues,
  })
}

const Slider = ({
  children,
  initialValues,
  name,
  max,
  min,
  onChange,
  nonLinearSteps,
}: SliderProps) => (
  <SliderProvider {...{ initialValues, name, max, min, onChange, nonLinearSteps }}>
    <div className="block w-full relative">
      <NonLinearStepLabels />
      <SliderRenderProps>{children}</SliderRenderProps>
    </div>
  </SliderProvider>
)

Slider.Rail = Rail
Slider.Label = Label

export { Slider }
