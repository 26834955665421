import { useEffect } from 'react'
import { LineLayerSpecification } from 'mapbox-gl'
import { Source, Layer, useMap } from 'react-map-gl'
import { useRoute } from '@electro/consumersite/src/components/Map/hooks'
import { Route } from '@electro/consumersite/src/components/Map/types'

const GhostedPolyLine = ({ line, routeKey }: { routeKey: string; line: any }) => {
  const { baseMap } = useMap()
  const [, { selectActiveRoute }] = useRoute()

  const [
    {
      properties: { id: routeStepId },
    },
  ] = line.features
  const polyLineId = `${routeKey}-${routeStepId}`

  /**
   * We want to allow the user to be able to select a route
   * by clicking on it! To achieve this we need to access the
   * ref to the baseMap events API.
   */
  useEffect(() => {
    if (baseMap) {
      baseMap?.on('click', polyLineId, () => {
        selectActiveRoute(routeKey)
      })
    }
  }, [baseMap, polyLineId, routeKey, selectActiveRoute])

  const lineStyle: LineLayerSpecification = {
    id: polyLineId,
    type: 'line',
    slot: 'middle',
    source: polyLineId,
    paint: {
      'line-color': '#ccc',
      'line-opacity': 0.8,
      'line-width': 8,
    },
    layout: {
      visibility: 'visible',
      'line-cap': 'round',
      'line-join': 'round',
    },
  }

  return (
    <Source key={polyLineId} id={polyLineId} lineMetrics type="geojson" data={line}>
      <Layer {...lineStyle} />
    </Source>
  )
}

export const GhostRouteLayer = () => {
  const [{ routes }] = useRoute()

  return (
    <div data-testid="ghost-routes">
      {routes
        ? Object.keys(routes.data).map((routeKey: string) => {
            const route: Route = routes.data[routeKey]
            return route.polyLine.map((line) => (
              <GhostedPolyLine line={line} routeKey={routeKey} key={routeKey} />
            ))
          })
        : null}
    </div>
  )
}
