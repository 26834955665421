import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { Button, ButtonProps } from '@electro/shared-ui-components'
import { UserVehicleType } from '@electro/consumersite/generated/graphql'
import { PlusCircleIcon, EyeSlashIcon } from '@heroicons/react/16/solid'
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline'
import { ArrowsRightLeftIcon } from '@heroicons/react/20/solid'
import { MyEVsIcon } from '@electro/consumersite/src/icons'

const styles = {
  button: {
    root: 'flex flex-row justify-between shrink-0 py-1 px-4 [&>span]:-ml-4 rounded-lg hover:bg-secondary/10',
    isActive: 'bg-secondary/30 hover:bg-secondary/50',
  },
  label: {
    root: 'flex flex-col text-xs text-left leading-4 mr-6 [&>*]:m-0',
    title: 'text-primary-light font-semibold',
  },
  icon: 'w-6 text-white/90',
  plusIcon: 'absolute w-3 text-primary top-2 right-3',
}

interface MyEVsVehicleButtonProps extends ButtonProps {
  vehicle: UserVehicleType
  icon: 'arrowUpCircle' | 'arrowsRightLeft' | 'eyeSlash'
  isActive?: boolean
}

export const MyEVsVehicleButton = ({
  vehicle,
  icon,
  isActive,
  className,
  ...props
}: MyEVsVehicleButtonProps) => {
  const { t } = useTranslation('common')

  let title = t('map.my_evs.no_vehicles.none_selected')
  let subtext = t('map.my_evs.no_vehicles.click_here_to_manage')

  if (vehicle) {
    title = `${vehicle.vehicle.vehicleMake} ${vehicle.vehicle.vehicleModel}`
    subtext = vehicle.vehicle.vehicleModelVersion
  }

  return (
    <Button
      {...props}
      fullWidth
      variant="outline"
      className={tw({
        [styles.button.root]: true,
        [styles.button.isActive]: isActive,
        [className]: !!className,
      })}
    >
      <div className={styles.label.root}>
        <h4 className={styles.label.title}>{title}</h4>
        <p>{subtext}</p>
      </div>

      {vehicle ? (
        <>
          {icon === 'arrowUpCircle' && <ArrowUpCircleIcon className={styles.icon} />}
          {icon === 'arrowsRightLeft' && <ArrowsRightLeftIcon className={styles.icon} />}
          {icon === 'eyeSlash' && <EyeSlashIcon className={styles.icon} />}
        </>
      ) : (
        <span>
          <MyEVsIcon className={styles.icon} />
          <PlusCircleIcon className={styles.plusIcon} />
        </span>
      )}
    </Button>
  )
}
