// These constants define bounds for various map controls
// E.g. What padding to apply when in desktop/mobile view with the different UI elements

export const SPEED = 0.5

export const LOCATION_MAX_ZOOM = 12
export const COUNTRY_MAX_ZOOM = 5

export const PADDING = {
  sidebarAndDrawer: {
    DEFAULT: { top: 220, left: 70, right: 70, bottom: 70 },
    sm: { top: 220, left: 70, right: 70, bottom: 70 },
    md: { top: 180, left: 470, right: 470, bottom: 70 },
    lg: { top: 180, left: 470, right: 470, bottom: 70 },
    xl: { top: 180, left: 470, right: 470, bottom: 70 },
    '2xl': { top: 180, left: 470, right: 470, bottom: 70 },
  },
  sidebarOnly: {
    DEFAULT: { top: 220, left: 70, right: 70, bottom: 70 },
    sm: { top: 220, left: 70, right: 70, bottom: 70 },
    md: { top: 180, left: 470, right: 70, bottom: 70 },
    lg: { top: 180, left: 470, right: 70, bottom: 70 },
    xl: { top: 180, left: 470, right: 70, bottom: 70 },
    '2xl': { top: 180, left: 470, right: 70, bottom: 70 },
  },
  drawerOnly: {
    DEFAULT: { top: 220, left: 70, right: 70, bottom: 70 },
    sm: { top: 220, left: 70, right: 70, bottom: 70 },
    md: { top: 180, left: 70, right: 470, bottom: 70 },
    lg: { top: 180, left: 70, right: 470, bottom: 70 },
    xl: { top: 180, left: 70, right: 470, bottom: 70 },
    '2xl': { top: 180, left: 70, right: 470, bottom: 70 },
  },
  none: {
    DEFAULT: { top: 220, left: 70, right: 70, bottom: 70 },
    sm: { top: 220, left: 70, right: 70, bottom: 70 },
    md: { top: 180, left: 70, right: 70, bottom: 70 },
    lg: { top: 180, left: 70, right: 70, bottom: 70 },
    xl: { top: 180, left: 70, right: 70, bottom: 70 },
    '2xl': { top: 180, left: 70, right: 70, bottom: 70 },
  },
}
