const connectorMap = {
  CCS: '/images/connectors/CCS.svg',
  'Type 2': '/images/connectors/Type_2.svg',
  CHAdeMO: '/images/connectors/CHAdeMO.svg',
  '3 pin': '/images/connectors/3_Pin.svg',
  Type_3C: '/images/connectors/Type_3C.svg',
  'Tesla Connector': '/images/connectors/Type_2.svg',
  'Tesla Roadster Connector': '/images/connectors/Other.svg',
  'Type 3A': '/images/connectors/Type_3.svg',
  'Type 1 ccs': '/images/connectors/Type_3.svg',
  'Type 1': '/images/connectors/Type_1.svg',
  'Industrial Connector': '/images/connectors/Other.svg',
  Domestic: '/images/connectors/Other.svg',
  Schuko: '/images/connectors/Schuko.svg',
  '2 pin': '/images/connectors/2_Pin.svg',
}

export type ConnectorNames = keyof typeof connectorMap | 'Other'

/** Match each recognised connector name to an icon, else display a fallback icon */
export const mapConnectorNameToIcon = (connectorName: ConnectorNames) =>
  connectorMap[connectorName] || '/images/connectors/Other.svg'
