export const LinkedInSvg = ({ className }: { className: string }) => (
  <svg
    aria-label="LinkedIn Icon"
    className={className}
    fill="none"
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0ZM35.8373 37C36.4794 37 37 36.4786 37 35.8355V27.4737C37 22.7972 35.9936 19.202 30.5371 19.202C29.5133 19.1638 28.4983 19.4046 27.6003 19.8985C26.7097 20.3884 25.9671 21.1091 25.4504 21.985C25.4424 21.9986 25.4278 22.0069 25.4121 22.0069C25.3876 22.0069 25.3678 21.9871 25.3678 21.9626V20.7979C25.3678 20.1548 24.8472 19.6334 24.2051 19.6334H21.3625C20.7204 19.6334 20.1998 20.1547 20.1998 20.7979V35.8348C20.1998 36.4779 20.7204 36.9993 21.3625 36.9993H24.4205C25.0626 36.9993 25.5831 36.4779 25.5831 35.8348V28.405C25.5831 26.1397 26.0125 23.9455 28.8163 23.9455C31.5805 23.9455 31.6167 26.539 31.6167 28.553V35.8355C31.6167 36.4786 32.1372 37 32.7794 37H35.8373ZM12.3891 16.7321C12.903 17.0759 13.5072 17.2593 14.1252 17.2592C14.9537 17.259 15.7482 16.9292 16.334 16.3423C16.9198 15.7554 17.2489 14.9595 17.2489 14.1296C17.2489 13.5106 17.0657 12.9055 16.7223 12.3908C16.379 11.8761 15.8909 11.475 15.32 11.2382C14.749 11.0013 14.1208 10.9394 13.5147 11.0602C12.9085 11.181 12.3518 11.4791 11.9149 11.9169C11.478 12.3546 11.1804 12.9123 11.06 13.5195C10.9395 14.1266 11.0014 14.7559 11.238 15.3277C11.4746 15.8995 11.8752 16.3883 12.3891 16.7321ZM15.6542 36.9993C16.2963 36.9993 16.8168 36.478 16.8169 35.8349L16.8175 20.7979C16.8175 20.1548 16.2969 19.6334 15.6548 19.6334H12.5906C11.9485 19.6334 11.4279 20.1547 11.4279 20.7979V35.8348C11.4279 36.4779 11.9485 36.9993 12.5906 36.9993H15.6542Z"
      className="fill-current"
    />
  </svg>
)
