import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { useState } from 'react'

import { Button, Modal } from '@electro/shared-ui-components'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'
import {
  getActiveHolidaySeason,
  getSeasonalImagePath,
} from '@electro/consumersite/src/helpers/seasonalRendering'

export const DownloadAppQRButton = () => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation('common')
  const activeHolidaySeason = getActiveHolidaySeason()

  return (
    <>
      <Button
        size="xs"
        className="tracking-widest uppercase shrink-0"
        aria-label="Open download QR modal"
        onClick={() => {
          setShow(true)
          GTM.showAppQRCode()
        }}
        data-testid="download-app-qr-button"
      >
        📱 {t('nav.menu.get_the_app.label')}
      </Button>
      <Modal size="md" open={show} onClose={() => setShow(false)}>
        <Modal.CloseButton />
        <Modal.Header>📱 {t('nav.menu.get_the_app.header')}</Modal.Header>
        <Modal.Body>
          <p data-testid="qr-code-modal-text">{t('nav.menu.get_the_app.modal_text')}</p>
          <div className="flex flex-col items-center">
            <Image
              alt="QR code for downloading the app."
              width="300"
              height="300"
              src="/images/download-qr-code-transparent-white.png"
            />
          </div>
          {activeHolidaySeason ? (
            <Image
              src={getSeasonalImagePath('constantine', activeHolidaySeason)}
              width={72}
              height={69}
              alt="Constantine dressed up for the holidays!"
              className="absolute bottom-4 right-4"
            />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  )
}
