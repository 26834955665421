/* eslint-disable no-underscore-dangle */
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useMap } from 'react-map-gl'

import { useErrorNotificationEffect } from '@electro/shared/hooks'

import { ChargingLocationMetadataType } from '@electro/consumersite/generated/graphql'
import { EjnMarker, SearchMarker } from '@electro/consumersite/src/components/Map/types'
import { useFetchChargingLocation } from '@electro/consumersite/src/services'
import { useMapLocationID } from '@electro/consumersite/src/components/Map/hooks'

interface UseMarkerContext {
  activeLocationMarker: EjnMarker
  setActiveLocationMarker: (marker: EjnMarker) => void
  clearActiveMarker: () => void
  toggleLocationDetailsPanel: ({ open }: { open: boolean }) => void
  locationDetailsOpen: boolean
  locationData: ChargingLocationMetadataType
  locationLoading: boolean
  searchMarker: SearchMarker
  setSearchMarker: (marker: SearchMarker) => void
  clearSearchMarker: () => void
  handleSelectRouteMarker: (marker: EjnMarker) => void
  handleSelectLocation: (location) => void
}

export function useMarkerProvider() {
  const [getLocation, getLocationQuery] = useFetchChargingLocation({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const { errorNotification } = useErrorNotificationEffect({
    error: getLocationQuery.error,
    message: getLocationQuery.error?.message,
  })

  const { baseMap } = useMap()
  const [searchMarker, setSearchMarker] = useState<SearchMarker>(null)
  const [locationDetailsOpen, setDetailsOpen] = useState<boolean>(false)
  const [activeLocationMarker, setActiveLocationMarker] = useState<EjnMarker>(null)
  const { addLocationIDToURL } = useMapLocationID()

  const clearSearchMarker = useCallback(() => setSearchMarker(null), [])

  const toggleLocationDetailsPanel = ({ open }: { open?: boolean } = {}) => {
    setDetailsOpen((oldValue) => {
      if (open === false) return open
      if (open) return open
      return !oldValue
    })
  }

  const handleSelectRouteMarker = async (marker: EjnMarker) => {
    toggleLocationDetailsPanel({ open: true })
    try {
      const { data } = await getLocation({ variables: { pk: marker.id } })
      addLocationIDToURL(data?.chargingLocation?.chargingLocationPk)

      setActiveLocationMarker({ ...marker })
    } catch (err) {
      errorNotification()
    }
  }
  /**
   * @handleSelectLocation
   * Elastic search returns different lat lng values at various zoom levels!
   * This was causing an issue where a marker clicked on at a high zoom level would
   * have a mismatch in coordinates when zoomed in.
   * In order to ensure our users see the correct location at all zoom levels
   * the active marker pin is set on click using the location coords returned by elastic search,
   * then it updates once we have coordinates data from the `chargingLocation` endpoint
   */
  const handleSelectLocation = useCallback(
    async ({ location }) => {
      toggleLocationDetailsPanel({ open: true })
      setActiveLocationMarker({
        id: location?.properties._id,
        icon: location?.properties.is_ejn_location ? 'active-ejn' : 'active',
        coordinates: [location?.geometry?.coordinates[0], location?.geometry?.coordinates[1]],
        isEjnLocation: location?.properties.is_ejn_location,
      })
      try {
        const getLocationQueryResponse = await getLocation({
          variables: { pk: location?.properties._id },
        })

        addLocationIDToURL(location?.properties._id)
        setActiveLocationMarker({
          id: location?.properties._id,
          icon: location?.properties.is_ejn_location ? 'active-ejn' : 'active',
          coordinates: [
            getLocationQueryResponse.data.chargingLocation?.coordinates.longitude,
            getLocationQueryResponse.data.chargingLocation?.coordinates.latitude,
          ],
          isEjnLocation: location?.properties.is_ejn_location,
        })
      } catch (err) {
        errorNotification()
        toggleLocationDetailsPanel({ open: false })
      }
    },
    [errorNotification, getLocation, addLocationIDToURL],
  )

  /**
   * When the basemap is present we want to bind some event listeners to our markers.
   *
   * NOTE: In order to prevent multiple event listeners from firing we use a ref to
   * track if the listener has been bound to a given set of layers. This is
   * important because the handleSelectLocation function is called multiple times
   * if the user clicks on multiple markers eventually resulting in performance issues
   * and browser errors.
   */
  const hasMarkerListenerBeenBound = useRef(false)
  useEffect(() => {
    const selectMarkerListener = () => {
      if (baseMap && !hasMarkerListenerBeenBound.current) {
        baseMap?.on('click', 'charging_locations_non_ejn_circles', (e) => {
          const [location] = e.features
          handleSelectLocation({ location })
        })
        baseMap?.on('click', 'charging_locations_ejn_symbols', (e) => {
          const [location] = e.features
          handleSelectLocation({ location })
        })
        baseMap?.on('click', 'charging_locations_all', (e) => {
          const [location] = e.features
          handleSelectLocation({ location })
        })
        hasMarkerListenerBeenBound.current = true
      }
    }
    selectMarkerListener()
  }, [baseMap, handleSelectLocation])

  function clearActiveMarker() {
    setActiveLocationMarker(null)
  }

  return {
    searchMarker,
    setSearchMarker,
    clearSearchMarker,
    toggleLocationDetailsPanel,
    locationDetailsOpen,
    activeLocationMarker,
    setActiveLocationMarker,
    handleSelectLocation,
    clearActiveMarker,
    locationLoading: getLocationQuery.loading,
    locationData: getLocationQuery.data?.chargingLocation as ChargingLocationMetadataType,
    handleSelectRouteMarker,
  }
}

const MarkerContext = createContext<UseMarkerContext>(null)

export const useMarkers = () => {
  const context = useContext(MarkerContext)
  if (!context) {
    throw new Error(
      `useMarkers() hook cannot be used outside the context of <MarkersContextProvider/> `,
    )
  }
  return context
}

export const MarkersContextProvider = ({ children }) => {
  const context = useMarkerProvider()

  return <MarkerContext.Provider value={context}>{children}</MarkerContext.Provider>
}
