/* eslint-disable import/no-duplicates */
import { CountryCode, CountryCodes } from '@electro/shared/types/countries'
import { Locale, Locales } from '@electro/shared/types/locales'
import { de, enGB, es, fr, it } from 'date-fns/locale'
import { Locale as DateLocale } from 'date-fns'
import countries from 'i18n-iso-countries'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

interface LocaleMapResult {
  alpha3: CountryCode
  alpha2: string
  dateFormat: DateLocale
}

const localeMap: Record<string, LocaleMapResult> = {
  [Locales.EN]: { alpha3: CountryCodes.GBR, alpha2: 'GB', dateFormat: enGB },
  [Locales.FR]: { alpha3: CountryCodes.FRA, alpha2: 'FR', dateFormat: fr },
  [Locales.FR_FR]: { alpha3: CountryCodes.FRA, alpha2: 'FR', dateFormat: fr },
  [Locales.ES]: { alpha3: CountryCodes.ESP, alpha2: 'ES', dateFormat: es },
  [Locales.IT]: { alpha3: CountryCodes.ITA, alpha2: 'IT', dateFormat: it },
  [Locales.DE]: { alpha3: CountryCodes.DEU, alpha2: 'DE', dateFormat: de },
}

const countryCodeMap: { [key: string]: { locale: Locales } } = {
  [CountryCodes.GBR]: { locale: Locales.EN },
  [CountryCodes.FRA]: { locale: Locales.FR },
  [CountryCodes.ESP]: { locale: Locales.ES },
  [CountryCodes.ITA]: { locale: Locales.IT },
  [CountryCodes.DEU]: { locale: Locales.DE },
}

export const mapLocaleToCountryDetails = (locale: Locale | string): LocaleMapResult =>
  localeMap[locale] || localeMap[Locales.EN]

export const mapAlpha3CountryCodeToLocale = (countryCode: string): Locale =>
  countryCodeMap[countryCode]?.locale || countryCodeMap[CountryCodes.GBR].locale

export const mapCountryCodeToName = (countryCode: string) => countries.getName(countryCode, 'en')
