import { useMemo } from 'react'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import {
  useAccountManageMobileStep,
  AccountManageMobileStepsEnum,
} from '@electro/consumersite/src/components/AccountManageLayout/hooks'
import { useFeatureFlag } from '@electro/consumersite/src/hooks'
import {
  ManageOutstandingPayments,
  ManagePaymentMethods,
} from '@electro/consumersite/src/components/AccountPaymentsManager/components'
import { IconButton, Typography, Skeleton } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { DisableContentWrapper } from '@electro/consumersite/src/components'
import { useUserQuery } from '@electro/consumersite/generated/graphql'
import { ACCOUNT_ID } from '@electro/consumersite/src/constants/localStorage'
import { isActiveAccountFleetDriver } from '@electro/consumersite/src/utils/isActiveAccountFleetDriver'

export const AccountPaymentsManager = () => {
  const { t } = useTranslation('common')
  const { setStep } = useAccountManageMobileStep()
  const { data: userData, loading: userLoading } = useUserQuery({ fetchPolicy: 'network-only' })
  const activeAccountId = window?.localStorage?.getItem(ACCOUNT_ID)
  const showDebtWarningFeature = useFeatureFlag(
    process.env.NEXT_PUBLIC_FEATURE_FLAG_DEBT_PREVENTION === 'on',
  )

  const activeUserIsAFleetDriver = useMemo(
    () => isActiveAccountFleetDriver(userData?.me?.accounts, activeAccountId) && !userLoading,
    [userData?.me?.accounts, activeAccountId, userLoading],
  )

  if (userLoading)
    return (
      <div
        className="flex flex-col gap-4 sm:basis-[450px] opacity-40"
        data-testid="loading-rows-placeholder"
      >
        <Skeleton className="w-1/2 mb-4" />
        <Skeleton className="w-full h-16 mb-4" />
        <Skeleton className="w-full h-16 mb-4" />
        <Skeleton className="w-full h-16 mb-4" />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Skeleton className="h-12" variant="circular" />
          <Skeleton className="h-12" variant="circular" />
        </div>
      </div>
    )

  return (
    <div className="flex flex-col flex-1">
      <div className="flex justify-between">
        <IconButton
          className="block md:hidden"
          onClick={() => setStep(AccountManageMobileStepsEnum.ACCOUNT_MANAGE_MENU)}
        >
          <ArrowLeftIcon className="w-6 h-6" />
        </IconButton>
        <Typography
          className="block md:hidden"
          variant="h3"
          as="h1"
          data-testid="payment-method-page-title"
        >
          {t('profile.navigation.payment_methods')}
        </Typography>
        <div />
      </div>

      <div className="flex flex-col">
        <div className="flex">
          <div className="grow sm:grow-0 sm:basis-[550px]">
            <DisableContentWrapper
              condition={activeUserIsAFleetDriver}
              showContent={!activeUserIsAFleetDriver}
              message={t('fleet_driver_account.alert_messages.manage_payments')}
            >
              <ManagePaymentMethods />
              {showDebtWarningFeature ? <ManageOutstandingPayments /> : null}
            </DisableContentWrapper>
          </div>
        </div>
      </div>
    </div>
  )
}
