import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'

import { Button, Typography } from '@electro/shared-ui-components'

interface PaginationProps {
  totalResults: number
  currentStartRange: number
  currentEndRange: number
  onClickPrevious: () => void
  onClickNext: () => void
  nextDisabled: boolean
  previousDisabled: boolean
}

export const Pagination = ({
  totalResults,
  currentStartRange,
  currentEndRange,
  onClickPrevious,
  onClickNext,
  nextDisabled,
  previousDisabled,
}: PaginationProps) => (
  <nav
    className="flex flex-row justify-between items-center w-full border-t-2 pt-8 border-none"
    data-testid="pagination-nav"
  >
    <Button
      disabled={previousDisabled}
      onClick={onClickPrevious}
      variant="outline"
      size="xs"
      aria-label="Previous page"
      className="w-1/3"
    >
      <ChevronLeftIcon className="w-5 h-5 -ml-3 -mt-0.5" />
      <span>Previous</span>
    </Button>
    <Typography variant="small" className="text-center">
      {currentStartRange} to {currentEndRange} of <br className="md:hidden" /> {totalResults}
    </Typography>
    <Button
      disabled={nextDisabled}
      onClick={onClickNext}
      variant="outline"
      size="xs"
      className="w-1/3"
      aria-label="Next page"
    >
      <span>Next</span>
      <ChevronRightIcon className="w-5 h-5 -mr-3 -mt-0.5" />
    </Button>
  </nav>
)
