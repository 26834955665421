import Image from 'next/image'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { MapFiltersOperatorType } from '@electro/consumersite/src/components/Map/types'
import { CheckIcon } from '@heroicons/react/16/solid'

const styles = {
  root: 'flex items-center gap-x-2 py-px px-2 border-t border-gray-500/80',
  partnerIcon: 'min-w-4 max-w-4 [&>svg]:h-4 [&>svg]:w-4',
  text: 'mb-0 truncate min-w-0',
  checkIcon: 'w-4 h-4 ml-auto',
}

interface OperatorListTableItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  operator: MapFiltersOperatorType
  isSelected?: boolean
}

/** Consistent button to check/uncheck an operator in the list */
export const OperatorListTableItem = ({
  operator,
  isSelected,
  ...props
}: OperatorListTableItemProps) => (
  <button {...props} className={tw(styles.root, props.className)}>
    <span className={styles.partnerIcon}>
      {operator.isEjn ? (
        <Image
          src="/images/ElectroversePartnerBoltCircle.svg"
          alt="Electroverse Partner Icon"
          height={19}
          width={19}
        />
      ) : null}
    </span>

    <p className={styles.text}>{operator.name}</p>

    {isSelected && <CheckIcon className={styles.checkIcon} />}
  </button>
)
