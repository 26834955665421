export const ConstantineSvg = ({ className }: { className: string }) => (
  <svg
    aria-label="constantine"
    className={className}
    width="18"
    height="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill-current"
      d="M15.567 14.257c.261-1.238 2.17-3.04 2.399-5.453C18.267 5.63 16.676 0 8.901 0 1.125 0 0 5.805 0 8.244c0 3.238 2.36 4.913 2.253 6.066l.022.02c.063 1.24-1.235 1.316-1.17 2.087.064.608 1.733.74 2.902.11a2.29 2.29 0 01-.757.878c-.609.325-1.09.498-1.09.878s1.797 1.317 3.637.391a4.515 4.515 0 001.235-.847c0 .065.022.13.022.173.087.523.39 2 1.732 2 1.341 0 1.61-1.326 1.825-2.097a.616.616 0 00.044-.195c.387.382.833.698 1.322.935 1.84.913 3.638-.022 3.638-.39 0-.37-.476-.523-1.09-.879a2.208 2.208 0 01-.803-.913c1.169.718 2.946.564 3.01-.044.065-.781-1.31-.847-1.17-2.173.075-.027-.017.013.005.013z"
    />
  </svg>
)
