import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'

/** Only retrieves the coordinates for a quicker response time */
const CHARGING_LOCATION_COORDS_QUERY = gql`
  query chargingLocation($pk: String!) {
    chargingLocation(pk: $pk) {
      coordinates
    }
  }
`

interface Handlers {
  getMapCentreFromLocationID: (locationID: string) => Promise<{ lat: number; lng: number }>
  addLocationIDToURL: (locationId: string) => void
  removeLocationIDFromURL: () => void
}

export const useMapLocationID = (): Handlers => {
  const router = useRouter()

  const { refetch } = useQuery(CHARGING_LOCATION_COORDS_QUERY, { skip: true })

  /** When a location is clicked or shared via the URL, we set the map centre to the charging location */
  const getMapCentreFromLocationID = useCallback(
    async (locationID: string): Promise<{ lat: number; lng: number }> => {
      const { data } = await refetch({ pk: locationID })
      const { longitude, latitude } = data?.chargingLocation?.coordinates || {}

      return { lat: parseFloat(latitude) || null, lng: parseFloat(longitude) || null }
    },
    [refetch],
  )

  /** Adds chargingLocationPk to the URL to enable location sharing and mobile deep linking */
  const addLocationIDToURL = useCallback(
    (locationId: string) => {
      if (router.asPath) {
        const splitURL = window.location.pathname.split('/')
        const locationIndex = splitURL.indexOf('location')

        if (locationId) {
          if (locationIndex > 0) splitURL.splice(locationIndex + 1, 1, locationId)
          else splitURL.push('location', locationId)

          const urlPath = splitURL.join('/')
          const urlQueryParams = window.location.search

          router.replace(`${urlPath}${urlQueryParams}`, undefined, { shallow: true })
        }
      }
    },
    [router],
  )

  /** Removes /location/123456 from the URL if it exists */
  const removeLocationIDFromURL = useCallback(() => {
    if (router.asPath) {
      const splitURL = window.location.pathname.split('/')
      const locationIndex = splitURL.indexOf('location')

      if (locationIndex > 0) {
        splitURL.splice(locationIndex, 2)

        const urlPath = splitURL.join('/')
        const urlQueryParams = window.location.search

        router.replace(`${urlPath}${urlQueryParams}`, undefined, { shallow: true })
      }
    }
  }, [router])

  const handlers = useMemo(
    () => ({ getMapCentreFromLocationID, addLocationIDToURL, removeLocationIDFromURL }),
    [getMapCentreFromLocationID, addLocationIDToURL, removeLocationIDFromURL],
  )

  return handlers
}
