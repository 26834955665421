export const searchbarSuggestions = {
  "en-GB": [
    {
      "id": "dXJuOm1ieHBvaTpjYmIwOTBlZC03YWY2LTQwZjMtODY4Mi1jYmMyYzE4NjFiZjQ",
      "name": "Natural History Museum",
      "subtext": "Cromwell Road, London, SW7 5BD, United Kingdom",
      "type": "poi",
      "coordinates": {
        "lng": -0.176206,
        "lat": 51.4964692
      }
    },
    {
      "id": "dXJuOm1ieHBsYzo2MUV1VHc",
      "name": "EH1 2NG",
      "subtext": "Edinburgh, Edinburgh, Scotland, United Kingdom",
      "type": "postcode",
      "coordinates": {
        "lng": -3.201478,
        "lat": 55.9489652
      }
    },
    {
      "id": "dXJuOm1ieHBsYzpEYVJO",
      "name": "Paris",
      "subtext": "France",
      "type": "region",
      "coordinates": {
        "lng": 2.3483915,
        "lat": 48.8534951
      },
      "bbox": [
        2.224225,
        48.815607,
        2.469709,
        48.902012
      ]
    },
    {
      "id": "123456789",
      "name": "Octopus HQ",
      "subtext": "38°10'24.0\"N 27°00'33.0\"W",
      "coordinates": {
        "lng": -27.009158334665926,
        "lat": 38.17333864152954
      },
      "type": "octopus_hq",
      "maxZoom": 7
    }
  ],
  "es": [
    {
      "id": "dXJuOm1ieHBvaTpiYmQwY2NmMS04NjFiLTQwZTAtYTE2My1jYzNiM2JlNGM0NTQ",
      "name": "Museo Nacional del Prado",
      "subtext": "Calle Ruiz De Alarcón 23, 28014 Madrid, España",
      "type": "poi",
      "coordinates": {
        "lng": -3.692235,
        "lat": 40.413822
      }
    },
    {
      "id": "dXJuOm1ieHBsYzppVzVH",
      "name": "08001",
      "subtext": "Barcelona, provincia de Barcelona, España",
      "type": "postcode",
      "coordinates": {
        "lng": 2.16817,
        "lat": 41.379676
      },
      "bbox": [
        2.163114,
        41.374348,
        2.17752,
        41.386089
      ]
    },
    {
      "id": "dXJuOm1ieHBsYzpEYVJO",
      "name": "París",
      "subtext": "Francia",
      "type": "region",
      "coordinates": {
        "lng": 2.3483915,
        "lat": 48.8534951
      },
      "bbox": [
        2.224225,
        48.815607,
        2.469709,
        48.902012
      ]
    },
    {
      "id": "123456789",
      "name": "Sede del pulpo",
      "subtext": "38°10'24.0\"N 27°00'33.0\"W",
      "coordinates": {
        "lng": -27.009158334665926,
        "lat": 38.17333864152954
      },
      "type": "octopus_hq",
      "maxZoom": 7
    }
  ],
  "fr": [
    {
      "id": "dXJuOm1ieHBvaTo3Y2JkMTJjNy1kMjAxLTQ3YTktOTM5ZC1lNmRhOTNhZDAwMDc",
      "name": "Parc National des Calanques",
      "subtext": "4 Quai Du Port, 13009 Marseille, France",
      "type": "poi",
      "coordinates": {
        "lng": 5.431351,
        "lat": 43.218128
      }
    },
    {
      "id": "dXJuOm1ieHBsYzpBbU5PVFE",
      "name": "76790",
      "subtext": "Étretat, Seine-Maritime, France",
      "type": "postcode",
      "coordinates": {
        "lng": 0.202447,
        "lat": 49.708131
      },
      "bbox": [
        0.180072,
        49.669602,
        0.342603,
        49.731134
      ]
    },
    {
      "id": "dXJuOm1ieHBsYzpBdE1JY0E",
      "name": "Rome",
      "subtext": "ville métropolitaine de Rome Capitale, Italie",
      "type": "place",
      "coordinates": {
        "lng": 12.476601,
        "lat": 41.900196
      },
      "bbox": [
        12.2236583,
        41.6353959,
        12.855839,
        42.140969
      ]
    },
    {
      "id": "123456789",
      "name": "Siège social de Octopus",
      "subtext": "38°10'24.0\"N 27°00'33.0\"W",
      "coordinates": {
        "lng": -27.009158334665926,
        "lat": 38.17333864152954
      },
      "type": "octopus_hq",
      "maxZoom": 7
    }
  ],
  "it": [
    {
      "id": "dXJuOm1ieHBvaTpjYTNlOWE0NS0zZTBkLTRlNjAtYTlkZC03Njg0ZjM0MWZmMDI",
      "name": "Colosseum",
      "subtext": "Piazza Del Colosseo 1, 00184 Roma, Italia",
      "type": "poi",
      "coordinates": {
        "lng": 12.491461,
        "lat": 41.891815
      }
    },
    {
      "id": "dXJuOm1ieHBsYzpBWVJPY0E",
      "name": "56125",
      "subtext": "Pisa, provincia di Pisa, Italia",
      "type": "postcode",
      "coordinates": {
        "lng": 10.401336,
        "lat": 43.714963
      },
      "bbox": [
        10.390777,
        43.703355,
        10.409801,
        43.715927
      ]
    },
    {
      "id": "dXJuOm1ieHBsYzpkZWhH",
      "name": "Barcellona",
      "subtext": "provincia di Barcellona, Spagna",
      "type": "place",
      "coordinates": {
        "lng": 2.16538,
        "lat": 41.38723
      },
      "bbox": [
        2.052453,
        41.2986947,
        2.2414919,
        41.468253
      ]
    },
    {
      "id": "123456789",
      "name": "Quartier generale del polpo",
      "subtext": "38°10'24.0\"N 27°00'33.0\"W",
      "coordinates": {
        "lng": -27.009158334665926,
        "lat": 38.17333864152954
      },
      "type": "octopus_hq",
      "maxZoom": 7
    }
  ],
  "de": [
    {
      "id": "dXJuOm1ieHBvaTowMTljYzA3ZC1mZGEzLTQ0NmQtYTE3Mi1lODgyMThmN2M0MWI",
      "name": "Cologne Cathedral",
      "subtext": "Domkloster 4, 50667 Köln, Deutschland",
      "type": "poi",
      "coordinates": {
        "lng": 6.956279,
        "lat": 50.94139
      }
    },
    {
      "id": "dXJuOm1ieHBsYzpBZy91T2c",
      "name": "60594",
      "subtext": "Frankfurt am Main, Hessen, Deutschland",
      "type": "postcode",
      "coordinates": {
        "lng": 8.689748,
        "lat": 50.104852
      },
      "bbox": [
        8.676972,
        50.097079,
        8.728054,
        50.10853
      ]
    },
    {
      "id": "dXJuOm1ieHBsYzpBdE1JY0E",
      "name": "Rom",
      "subtext": "Metropolitanstadt Rom, Italien",
      "type": "place",
      "coordinates": {
        "lng": 12.476601,
        "lat": 41.900196
      },
      "bbox": [
        12.2236583,
        41.6353959,
        12.855839,
        42.140969
      ]
    },
    {
      "id": "123456789",
      "name": "Octopus-Hauptquartier",
      "subtext": "38°10'24.0\"N 27°00'33.0\"W",
      "coordinates": {
        "lng": -27.009158334665926,
        "lat": 38.17333864152954
      },
      "type": "octopus_hq",
      "maxZoom": 7
    }
  ]
}

export const countryBoundingBoxes = {
  "en-GB": {
    "id": "dXJuOm1ieHBsYzpJazg",
    "name": "United Kingdom",
    "type": "country",
    "coordinates": {
      "lng": -2.36966957036279,
      "lat": 54.2379333607472
    },
    "bbox": [
      -8.7497874,
      49.80777,
      1.8623843,
      60.9093508
    ]
  },
  "es": {
    "id": "dXJuOm1ieHBsYzpJa1k",
    "name": "España",
    "type": "country",
    "coordinates": {
      "lng": -4.05568501525488,
      "lat": 41.2948556295683
    },
    "bbox": [
      -18.260243,
      27.5545087,
      4.4264828,
      43.8640692
    ]
  },
  "fr": {
    "id": "dXJuOm1ieHBsYzpJazA",
    "name": "France",
    "type": "country",
    "coordinates": {
      "lng": 2.61878695312962,
      "lat": 47.8249046208979
    },
    "bbox": [
      -5.2425456,
      41.3108239,
      9.6598888,
      51.1504571
    ]
  },
  "it": {
    "id": "dXJuOm1ieHBsYzpJbkE",
    "name": "Italia",
    "type": "country",
    "coordinates": {
      "lng": 12.6463610364431,
      "lat": 42.5041539170675
    },
    "bbox": [
      6.626556,
      35.4122073,
      18.6201843,
      47.092146
    ]
  },
  "de": {
    "id": "dXJuOm1ieHBsYzpJam8",
    "name": "Deutschland",
    "type": "country",
    "coordinates": {
      "lng": 10.0183432948567,
      "lat": 51.1334813439932
    },
    "bbox": [
      5.866315,
      47.270238,
      15.041832,
      55.1286491
    ]
  }
}

