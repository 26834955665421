import Link from 'next/link'
import { useRouter } from 'next/router'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { EJLogoSvg } from '@electro/consumersite/src/svgs'
import { PrimaryNav } from '@electro/consumersite/src/components/TopNavigation/components/DesktopNavigation/components'
import {
  UserMenu,
  LoginButton,
  SignUpButton,
} from '@electro/consumersite/src/components/TopNavigation/components'
import {
  HideWhenSiteUnderMaintenance,
  LanguageSelect,
  DownloadAppQRButton,
} from '@electro/consumersite/src/components'
import { DesktopNavigationProvider } from '@electro/consumersite/src/components/TopNavigation/components/DesktopNavigation/hooks'
import {
  useDeviceInformation,
  OperatingSystemEnum,
} from '@electro/consumersite/src/hooks/useDeviceInformation'
import { useAuth } from '@electro/consumersite/src/hooks'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'

const styles = {
  root: 'flex items-center justify-between fixed top-0 z-40 w-full backdrop-blur-lg px-4',
  transparentBackground: 'bg-[#1C0388]/60', // Hardcode value to mimic the opacity bg color on the map page
  solidBackground:
    'bg-gradient-to-r from-[#1b014d]/90 via-[#350c77]/90 via-65% to-[#5014ac]/90 shadow-md', // Hardcoded values mimic the homepage gradient mesh
}

const DesktopNavigation = () => {
  const { asPath, push } = useRouter()
  const { deviceType } = useDeviceInformation()
  const [{ session, sessionLoading }] = useAuth()
  const isMapRoute = asPath.includes('/map')
  const isBusinessRoute = asPath.includes('/business')

  const handleSignUp = () => {
    GTM.clickSignUpOnNav()
    push('/sign-up/magic')
  }

  const handleLogin = () => {
    GTM.clickSignUpOnNav()
    push('/log-in')
  }

  return (
    <nav
      className={tw({
        [styles.root]: true,
        [styles.solidBackground]: !isMapRoute,
        [styles.transparentBackground]: isMapRoute,
      })}
      aria-label="navigation"
      data-testid="desktop-navigation"
    >
      <div className="flex w-full flex-shrink grow md:max-w-screen-2xs lg:max-w-screen-xs xl:max-w-screen-md 2xl:max-w-screen-lg h-16 items-center lg:gap-8 xl:gap-16 2xl:gap-32">
        <Link
          href="/"
          className="cursor-pointer self-center rounded-full focus:outline-primary-light"
          aria-label="Go to homepage"
        >
          <EJLogoSvg />
        </Link>
        <PrimaryNav />
      </div>
      <div className="flex self-center">
        <HideWhenSiteUnderMaintenance>
          {sessionLoading && null}

          {!sessionLoading && !session ? (
            <div
              className={tw(
                'flex flex-shrink w-full items-center',

                isBusinessRoute ? 'opacity-0 pointer-events-none' : 'opacity-100',
              )}
            >
              <SignUpButton onClick={handleSignUp} />
              <LoginButton onClick={handleLogin} />
              {deviceType === OperatingSystemEnum.DESKTOP ? <DownloadAppQRButton /> : null}
            </div>
          ) : (
            <UserMenu />
          )}
        </HideWhenSiteUnderMaintenance>
        <LanguageSelect />
      </div>
    </nav>
  )
}

const DesktopNavigationContainer = () => (
  <DesktopNavigationProvider>
    <DesktopNavigation />
  </DesktopNavigationProvider>
)

export { DesktopNavigationContainer as DesktopNavigation }
