import React, { useMemo } from 'react'
import { useAuth, useFeatureFlag } from '@electro/consumersite/src/hooks'
import { UserAccountSelect } from '@electro/consumersite/src/components'
import { Menu, ProfileAvatar } from '@electro/shared-ui-components'
import { CogIcon, ArrowRightStartOnRectangleIcon, LinkIcon } from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { DebtWarningStatus, useUserQuery } from '@electro/consumersite/generated/graphql'

const dotStyles = {
  wrapper: 'w-2.5 h-2.5 absolute bottom-[0.675rem] left-[0.675rem]', // please don't change bottom and left values as they are placing warning dot on the icon correctly
  pingShadow:
    'motion-safe:animate-ping block absolute h-full w-full rounded-full bg-action-danger/100 opacity-75',
  dot: 'relative block rounded-full w-2.5 h-2.5 bg-action-danger',
}

const AnimatedPingDot = () => (
  <span className={dotStyles.wrapper}>
    <span className={dotStyles.pingShadow} />
    <span className={dotStyles.dot} />
  </span>
)

const styles = {
  userMenuItemList: 'mt-4 md:mt-1 ring-white ring-1',
  userMenuButton: 'flex-shrink-0 px-0',
  userMenuActiveChevron: 'text-primary-light',
  userMenuItemIcon: 'w-5 h-5 mr-2',
  userMenuTitle: 'px-2 py-3 capitalize',
  userMenuDivider: 'border-white border-opacity-30 mx-4 mt-2 mb-0',
  menuItemsContainer: 'px-2 pb-2 w-56',
  userDebtWarning: {
    wrapper: 'relative',
  },
}

export const UserMenu = () => {
  const { t } = useTranslation('common')
  const [{ session }, { logout }] = useAuth()
  const router = useRouter()
  const { data: userData } = useUserQuery()
  const showDebtWarningFeature = useFeatureFlag(
    process.env.NEXT_PUBLIC_FEATURE_FLAG_DEBT_PREVENTION === 'on',
  )

  const userHasIssuedDebt = useMemo(
    () => userData?.me?.financialInfo?.debtStatus === DebtWarningStatus.Issued,
    [userData?.me?.financialInfo?.debtStatus],
  )
  const userHasEscalatedDebt = useMemo(
    () => userData?.me?.financialInfo?.debtStatus === DebtWarningStatus.Escalated,
    [userData?.me?.financialInfo?.debtStatus],
  )
  if (!session) return null

  let debtStatus: 'warning' | 'error' | undefined
  if (showDebtWarningFeature) {
    if (userHasIssuedDebt) debtStatus = 'warning'
    else if (userHasEscalatedDebt) debtStatus = 'error'
  }

  return (
    <Menu>
      <Menu.Button
        className={styles.userMenuButton}
        chevronActiveClassName={styles.userMenuActiveChevron}
        data-testid="user-menu-button"
      >
        <ProfileAvatar avatar={userData?.me?.customisationOptions?.avatar} status={debtStatus} />
      </Menu.Button>
      <Menu.MenuItemList className={styles.userMenuItemList}>
        {!!userData?.me?.firstName && (
          <div className={styles.userMenuTitle}>
            {t('user.menu.hi')}, {userData.me.firstName}
          </div>
        )}

        <UserAccountSelect />
        <div className={styles.menuItemsContainer}>
          {userData?.me?.showFleetPlatformLink && (
            <Menu.MenuItem
              onSelect={() =>
                window.open('https://fleets.electroverse.octopus.energy/dashboard', '_blank')
              }
            >
              <LinkIcon className={styles.userMenuItemIcon} />
              Go to Fleet Dashboard
            </Menu.MenuItem>
          )}
          <hr className={styles.userMenuDivider} />
          <Menu.MenuItem
            onSelect={() =>
              router.push({
                pathname: '/user/account',
                query: { menu: true },
              })
            }
          >
            {(userHasIssuedDebt || userHasEscalatedDebt) && showDebtWarningFeature ? (
              <div className={styles.userDebtWarning.wrapper}>
                <CogIcon className={styles.userMenuItemIcon} />
                <AnimatedPingDot />
              </div>
            ) : (
              <CogIcon className={styles.userMenuItemIcon} />
            )}
            {t('user.menu.manage_account')}
          </Menu.MenuItem>
          <hr className={styles.userMenuDivider} />
          <Menu.MenuItem onSelect={logout}>
            <ArrowRightStartOnRectangleIcon className={styles.userMenuItemIcon} />
            {t('user.menu.log_out')}
          </Menu.MenuItem>
        </div>
      </Menu.MenuItemList>
    </Menu>
  )
}
