import { ConnectorNames } from '@electro/consumersite/src/helpers'
import { Capabilities } from '@electro/consumersite/generated/graphql'

export enum MapFiltersEnum {
  ELECTROVERSE_COMPATIBLE = 'electroverseOnly',

  OPERATORS = 'operators',
  SOCKET_TYPES = 'socketTypes',

  CHARGE_POINT_SPEEDS = 'chargePointSpeeds',
  MIN_CHARGE_POINTS = 'minimumChargePoints',

  ACCESS = 'access',
}

const {
  ELECTROVERSE_COMPATIBLE,
  OPERATORS,
  SOCKET_TYPES,
  CHARGE_POINT_SPEEDS,
  MIN_CHARGE_POINTS,
  ACCESS,
} = MapFiltersEnum

export type MapFiltersType = {
  [ELECTROVERSE_COMPATIBLE]: boolean

  [OPERATORS]: MapFiltersOperatorType[]
  [SOCKET_TYPES]: ConnectorNames[]

  [CHARGE_POINT_SPEEDS]: number[]
  [MIN_CHARGE_POINTS]: number

  [ACCESS]: Capabilities[]
}

export const DEFAULT_FILTERS: MapFiltersType = {
  [ELECTROVERSE_COMPATIBLE]: true,

  [OPERATORS]: [],
  [SOCKET_TYPES]: [],

  [CHARGE_POINT_SPEEDS]: [0, 350],
  [MIN_CHARGE_POINTS]: 0,

  [ACCESS]: [],
}

export interface MapFiltersOperatorType {
  pk: number
  name: string
  isEjn: boolean
}
