import { useMemo } from 'react'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useFeatureFlag } from '@electro/consumersite/src/hooks'
import {
  useAccountManageMobileStep,
  AccountManageMobileStepsEnum,
} from '@electro/consumersite/src/components/AccountManageLayout/hooks'
import {
  ElectrocardNoPaymentMethodNotice,
  ManageElectrocards,
  ManageElectrocardOrders,
} from '@electro/consumersite/src/components/AccountElectrocardsManager/components'
import { DisableContentWrapper } from '@electro/consumersite/src/components'
import { Button, IconButton, Skeleton, Typography, Alert } from '@electro/shared-ui-components'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useUserQuery, DebtWarningStatus } from '@electro/consumersite/generated/graphql'
import { ACCOUNT_ID } from '@electro/consumersite/src/constants/localStorage'
import { isActiveAccountFleetDriver } from '@electro/consumersite/src/utils/isActiveAccountFleetDriver'

export const AccountElectrocardsManager = () => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { setStep } = useAccountManageMobileStep()
  const { data: userData, loading: userLoading } = useUserQuery({ fetchPolicy: 'network-only' })
  const activeAccountId = window?.localStorage?.getItem(ACCOUNT_ID)
  const showElectrocardOrdersFeature = useFeatureFlag(
    process.env.NEXT_PUBLIC_FEATURE_FLAG_ELECTROCARDS_ORDER === 'on',
  )

  const activeUserIsAFleetDriver = useMemo(
    () => isActiveAccountFleetDriver(userData?.me?.accounts, activeAccountId) && !userLoading,
    [userData?.me?.accounts, activeAccountId, userLoading],
  )

  const showNoPaymentMethod = useMemo(
    () => !userData?.me?.hasValidPaymentInstruction && !userLoading,
    [userLoading, userData],
  )

  /**
   * When user has Escalated debt we shoud show a warning message
   * and disable any functionality for ordering/activating cards
   */
  const userHasEscalatedDebt = useMemo(
    () => !userLoading && userData?.me?.financialInfo?.debtStatus === DebtWarningStatus.Escalated,
    [userLoading, userData],
  )

  if (userLoading)
    return (
      <div className="flex justify-center md:justify-start" data-testid="loading-user-electrocards">
        <Skeleton width={174} height={28} />
      </div>
    )

  return showNoPaymentMethod ? (
    <div className="grow sm:grow-0 sm:basis-[450px]">
      <DisableContentWrapper
        condition={activeUserIsAFleetDriver}
        message={t('fleet_driver_account.alert_messages.manage_electrocards')}
      >
        <ElectrocardNoPaymentMethodNotice />
      </DisableContentWrapper>
    </div>
  ) : (
    <div className="flex flex-col flex-1">
      <div className="flex justify-between">
        <IconButton
          className="block md:hidden"
          onClick={() => setStep(AccountManageMobileStepsEnum.ACCOUNT_MANAGE_MENU)}
        >
          <ArrowLeftIcon className="w-6 h-6" />
        </IconButton>
        <Typography variant="h3" as="h1" data-testid="electrocards-page-title">
          {t('profile.navigation.electrocards')}
        </Typography>
        <div />
      </div>

      <div className="flex flex-col mt-6">
        <div className="flex">
          <div className="grow sm:grow-0 sm:basis-[550px]">
            <DisableContentWrapper
              condition={activeUserIsAFleetDriver}
              message={t('fleet_driver_account.alert_messages.manage_electrocards')}
            >
              <ManageElectrocards />
              {userHasEscalatedDebt ? (
                <Alert variant="warn" className="mt-8 bg-action-danger">
                  {t('profile.electrocards.unresolved_debt_alert_message')}
                </Alert>
              ) : null}
              <div className="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-2 items-center">
                <Button
                  fullWidth
                  variant="outline"
                  onClick={() => router.push('/user/account/electrocards/order-electrocard')}
                  disabled={userHasEscalatedDebt}
                >
                  {t('profile.electrocards.actions.order_new_card')}
                </Button>

                <Button
                  fullWidth
                  onClick={() => router.push('/user/account/electrocards/activate-electrocard')}
                  disabled={userHasEscalatedDebt}
                >
                  {t('profile.electrocards.actions.activate_existing_card')}
                </Button>
              </div>
              {showElectrocardOrdersFeature ? <ManageElectrocardOrders /> : null}
            </DisableContentWrapper>
          </div>
        </div>
      </div>
    </div>
  )
}
