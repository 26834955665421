/**
 * Retrieve a users list of vehicles to store in state.
 * The active vehicle is stored in local storage to persist between sessions.
 *
 * It also handles the active/inactive vehicles for use in
 * Map Filters, Route Planner, My EVs, etc.
 */

import * as Sentry from '@sentry/nextjs'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocalStorage, useUpdateEffect } from 'react-use'
import { useAuth } from '@electro/consumersite/src/hooks'
import { ACTIVE_USER_VEHICLE } from '@electro/shared/constants'
import { ConnectorNames } from '@electro/consumersite/src/helpers'
import { UserVehicleType, useUserVehiclesQuery } from '@electro/consumersite/generated/graphql'
import { useRoutePlannerForm } from '@electro/consumersite/src/components/Map/hooks'

interface State {
  userVehicles: UserVehicleType[]
  activeVehicle: UserVehicleType
  activeVehicleSockets: ConnectorNames[]
  inactiveVehicles: UserVehicleType[]
  loading: boolean
}

interface Handlers {
  updateActiveVehicle: (vehicle?: UserVehicleType) => void

  // TODO
  // addUserVehicle: (vehicle: UserVehicleType) => void
  // removeUserVehicle: (vehicle: UserVehicleType) => void
}

type UseUserVehicles = [State, Handlers]
const UseUserVehiclesContext = createContext<UseUserVehicles>(null)

function useUserVehiclesProvider({ target }: { target: 'map' | 'account' }): UseUserVehicles {
  const [{ session }] = useAuth()
  const [, { updateFormField }] = useRoutePlannerForm({ skip: target === 'account' })
  const { loading, data, error } = useUserVehiclesQuery({ skip: !session })
  const [activeVehicleLocalStorage, setActiveVehicleLocalStorage] =
    useLocalStorage<string>(ACTIVE_USER_VEHICLE)

  const [userVehicles, setUserVehicles] = useState<UserVehicleType[]>([])
  const [activeVehicle, setActiveVehicle] = useState<UserVehicleType>()
  const [inactiveVehicles, setInactiveVehicles] = useState<UserVehicleType[]>([])

  /** Holds a memoised sorted version of the activeVehicle sockets to use elsewhere in the app */
  const activeVehicleSockets = useMemo(
    () =>
      [activeVehicle?.vehicle?.chargePlug, activeVehicle?.vehicle?.fastchargePlug]
        .filter(Boolean)
        .sort() as ConnectorNames[],
    [activeVehicle],
  )

  /** Change or deselect their preferred vehicle */
  const updateActiveVehicle = useCallback(
    (selectedVehicle?: UserVehicleType) => {
      if (userVehicles.length === 0) return
      const newActiveVehicle = selectedVehicle ?? userVehicles[0]

      setActiveVehicle(newActiveVehicle)
      setActiveVehicleLocalStorage(newActiveVehicle.pk.toString())
      updateFormField('activeVehicle', newActiveVehicle)
      setInactiveVehicles(userVehicles.filter((vehicle) => vehicle.pk !== newActiveVehicle.pk))
    },
    [userVehicles, setActiveVehicleLocalStorage, updateFormField],
  )

  /** If logged in, store the vehicle list from backend on page load */
  useEffect(() => {
    if (userVehicles.length === 0 && data?.userVehicles?.edges.length > 0) {
      setUserVehicles(data.userVehicles.edges.map((edge) => edge.node as UserVehicleType))
    } else if (error) {
      Sentry.captureException(error)
    }
  }, [userVehicles, data?.userVehicles, error])

  /** Update the active/inactive vehicles in state for use across the app */
  useUpdateEffect(() => {
    if (userVehicles.length > 0 && !userVehicles.includes(activeVehicle)) {
      const activeVehicleID = parseInt(activeVehicleLocalStorage, 10)
      const vehicleInfo = userVehicles.find((vehicle) => vehicle.pk === activeVehicleID)

      updateActiveVehicle(vehicleInfo)
    }
  }, [userVehicles, activeVehicle, activeVehicleLocalStorage, updateActiveVehicle])

  const state = useMemo(
    () => ({ userVehicles, activeVehicle, activeVehicleSockets, inactiveVehicles, loading }),
    [userVehicles, activeVehicle, activeVehicleSockets, inactiveVehicles, loading],
  )

  const handlers = useMemo(() => ({ updateActiveVehicle }), [updateActiveVehicle])

  return [state, handlers]
}

interface UseUserVehiclesProviderProps {
  target: 'map' | 'account'
  children: ReactNode | ReactNode[]
}

export const UseUserVehiclesProvider = ({ target, children }: UseUserVehiclesProviderProps) => {
  const ctx = useUserVehiclesProvider({ target })
  return <UseUserVehiclesContext.Provider value={ctx}>{children}</UseUserVehiclesContext.Provider>
}

export const useUserVehicles = (): UseUserVehicles => {
  const context = useContext(UseUserVehiclesContext)
  if (!context)
    throw new Error('useUserVehicles() cannot be used outside of <UseUserVehiclesProvider/>')
  return context
}
