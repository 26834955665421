import { RouteDetailsPanel } from '@electro/consumersite/src/components/Map/components/RoutePlanner/components'
import {
  useMapModal,
  useRoute,
  useMarkers,
  useMapConfig,
} from '@electro/consumersite/src/components/Map/hooks'
import { RoutePlannerIcon } from '@electro/consumersite/src/icons'
import { useAuth } from '@electro/consumersite/src/hooks'

import { ModalScreenNames } from '@electro/consumersite/src/components/Map/types'
import { Button } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'
import { useRouter } from 'next/router'

const styles = {
  root: 'group shadow-md z-10 flex items-center gap-1',
  text: 'hidden lg:block group-hover:block',
}

export const RoutePlanner = () => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const [, { setActiveModalScreen }] = useMapModal()
  const { toggleLocationDetailsPanel } = useMarkers()
  const [{ routes }, { toggleRouteDetailsPanel }] = useRoute()
  const [{ session }] = useAuth()
  const [{ allowUserLogin }] = useMapConfig()

  function handleOpenRoutePlanner() {
    if (!routes || !session) {
      GTM.openRoutePlanner()
      setActiveModalScreen(ModalScreenNames.ROUTE_PLAN_SCREEN)()
      toggleLocationDetailsPanel({ open: false })
    } else {
      toggleRouteDetailsPanel()
    }
  }

  return (
    <>
      {!routes && allowUserLogin && !router.asPath?.includes('v2') && false && (
        <Button
          size="xs"
          aria-label="Open route planner"
          onClick={handleOpenRoutePlanner}
          className={styles.root}
        >
          <RoutePlannerIcon />

          <span className={styles.text} aria-hidden>
            {t('map.route_planner.button_text')}
          </span>
        </Button>
      )}
      <RouteDetailsPanel />
    </>
  )
}
