import { useCallback, useState, useMemo } from 'react'
import { v1 as uuid } from 'uuid'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import Image from 'next/image'

import { useRoute, useMarkers } from '@electro/consumersite/src/components/Map/hooks'
import { getIconNameFromStep } from '@electro/consumersite/src/components/Map/helpers/getIconNameFromStep'
import getMilesFromMeters from '@electro/shared/utils/getMilesFromMeters'
import { BatteryCharge } from '@electro/consumersite/src/components/Map/components/RoutePlanner/components'
import durationFromSeconds from '@electro/shared/utils/durationFromSeconds'
import getMarkerIcon, {
  ICON_URL,
} from '@electro/consumersite/src/components/Map/helpers/getMarkerIcon'
import { Step } from '@electro/consumersite/generated/graphql'
import { useMap } from 'react-map-gl'
import { useMedia } from 'react-use'
import electroTheme from '@electro/shared/theme/electro'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import {
  formatDistanceToLocalisedValue,
  formatDurationToLocalisedValue,
} from '@electro/shared/utils/formatters'
import { Locales } from '@electro/shared/types/locales'
import { IconButton } from '@electro/shared-ui-components'

const styles = {
  root: 'pt-4 pb-4 px-2 sm:px-4',
  step: {
    collapseButton:
      'w-6 h-6 mr-2 mt-1 rounded-full text-tertiary-light hover:bg-tertiary hover:bg-opacity-10 hover:text-tertiary',
    root: tw(
      'flex items-start pb-2 pt-1 group relative',
      'first:pt-0',
      'before:content-[""]',
      'before:absolute',
      'before:left-11',
      'before:-ml-0.5',
      'before:h-full',
      'before:w-0',
      'before:top-8',
      'before:border-l-4',
      'before:border-dotted',
      'last:before:hidden last:pb-16',
    ),
    button: {
      root: tw(
        'text-tertiary-shade border-b pb-8',
        'text-left rounded-md relative ml-10 group',
        'w-[calc(100%-theme(spacing.10))]',
        'group-last:border-b-0',
        'text-white hover:text-tertiary border-none',
      ),
      active: 'border-tertiary',
      inactive: 'before:border-secondary',
      disabled: 'pointer-events-none',
    },
    marker: {
      destination: 'inline-block bg-white rounded-full',
      charge: tw(
        'inline-block bg-white',
        'rounded-full',
        'group-hover:ring-tertiary group-hover:ring-offset-white group-hover:bg-white',
      ),
      inactive: 'ring-tertiary-shade',
      active: 'ring-tertiary ring-offset-white text-tertiary',
      position: 'absolute -left-11 translate-x-0.5',
    },
    networkName: 'text-xs',
    name: 'text-sm font-medium',
    operatorName: 'text-xs',
    details: 'text-sm mb-1',
  },
}
interface StepButtonProps {
  step: Step
  index: number
  onShowSteps: () => void
  activeRouteSteps: Step[]
}

const StepButton = ({ step, index, onShowSteps, activeRouteSteps }: StepButtonProps) => {
  const router = useRouter()
  const [open, setOpen] = useState<boolean>(true)
  const { t } = useTranslation('common')
  const { activeLocationMarker, handleSelectRouteMarker } = useMarkers()
  const { baseMap } = useMap()
  const [, { getRouteMarkers }] = useRoute()
  const lgScreenMatch = useMedia(`(min-width: ${electroTheme.screens.lg})`)

  const chargeDuration: { hours: string; minutes: string } = useMemo(() => {
    const duration = durationFromSeconds(step?.chargeDuration)
    return {
      hours: formatDurationToLocalisedValue({
        duration: duration?.hours,
        locale: router.locale,
        unit: 'hour',
      }),
      minutes: formatDurationToLocalisedValue({
        duration: duration?.minutes,
        locale: router.locale,
        unit: 'minute',
      }),
    }
  }, [router.locale, step?.chargeDuration])

  const nextStepDistance = step.departureDist - (activeRouteSteps?.[index + 1]?.departureDist || 0)
  const nextStepDuration =
    step.departureDuration - (activeRouteSteps?.[index + 1]?.departureDuration || 0)
  const departureDuration = useMemo(() => {
    const duration = durationFromSeconds(nextStepDuration)
    return {
      hours: formatDurationToLocalisedValue({
        duration: duration?.hours,
        locale: router.locale,
        unit: 'hour',
      }),
      minutes: formatDurationToLocalisedValue({
        duration: duration?.minutes,
        locale: router.locale,
        unit: 'minute',
      }),
    }
  }, [nextStepDuration, router.locale])

  const departureDistance = formatDistanceToLocalisedValue({
    distance: getMilesFromMeters(nextStepDistance),
    locale: router.locale,
    unit: Locales.EN === router.locale ? 'mile' : 'kilometer',
    shortUnit: true,
  })
  const isDepartureStep = index === 0
  const isDestinationStep = index === activeRouteSteps.length - 1
  const selected =
    !!activeLocationMarker?.id &&
    (step?.charger?.ejnChargingLocationId === activeLocationMarker?.id ||
      step?.charger?.ecoChargingLocationId === activeLocationMarker?.id)

  function handleRouteMarker(stepEvent: Step) {
    const isCharger = !!stepEvent.charger

    if (isCharger && lgScreenMatch) {
      const [marker] = getRouteMarkers({ steps: [stepEvent] })

      if (marker) handleSelectRouteMarker(marker)
    }
  }

  function handleClickStep(stepEvent: Step) {
    return () => {
      onShowSteps()
      const [lng, lat] = [stepEvent.lon, stepEvent.lat]
      baseMap?.flyTo({ center: [lng, lat], zoom: 12, speed: 0.5 })

      handleRouteMarker(stepEvent)
    }
  }

  const stepIconUrl = useMemo(
    () =>
      getMarkerIcon({
        icon: getIconNameFromStep({ step, steps: activeRouteSteps, index }),
      }),
    [activeRouteSteps, index, step],
  )

  const toggleOpen = useCallback(() => {
    setOpen((oldOpen) => !oldOpen)
  }, [])

  return (
    <div className={styles.step.root} key={step.id}>
      <IconButton onClick={toggleOpen}>
        <ChevronDownIcon
          className={tw({
            [styles.step.collapseButton]: true,
            'rotate-180': !open,
          })}
        />
      </IconButton>
      <button
        onClick={handleClickStep(step)}
        key={uuid()}
        className={tw({
          [styles.step.button.root]: true,
          [styles.step.button.active]: selected,
          [styles.step.button.inactive]: !selected,
        })}
      >
        <div data-testid="marker" className={styles.step.marker.position}>
          {!selected && <Image src={stepIconUrl} alt="step marker" width={28} height={33} />}
          {selected && <Image src={ICON_URL.active} alt="active marker" width={28} height={33} />}
        </div>
        <div className={styles.step.name}>{step.name}</div>
        {!!step.charger?.networkName && (
          <div className={styles.step.networkName}>{step.charger?.networkName}</div>
        )}
        {open && (
          <div className={styles.step.details}>
            {!isDepartureStep && (
              <div className="flex items-center gap-2">
                {t('map.route_planner.route.arrival_charge')}: {step.arrivalPerc}%{' '}
                <BatteryCharge percent={step.arrivalPerc} />
              </div>
            )}
            {chargeDuration?.minutes || chargeDuration?.hours ? (
              <div>
                {t('map.route_planner.route.time_charging')}: {chargeDuration?.hours}{' '}
                {chargeDuration?.minutes}
              </div>
            ) : null}
            {!isDestinationStep && (
              <div className="flex items-center gap-2">
                {t('map.route_planner.route.departure_charge')}: {step.departurePerc}%{' '}
                <BatteryCharge percent={step.departurePerc} />
              </div>
            )}
          </div>
        )}
        {step.departureDist !== 0 && step.departureDuration > 0 && (
          <div className="text-xs absolute -bottom-3 py-1 pr-2 z-10 bg-transparent text-tertiary-light">
            {departureDuration?.hours} {departureDuration?.minutes} {departureDistance}
          </div>
        )}
      </button>
    </div>
  )
}

export const RouteDetailsPanelSteps = ({ onShowSteps }) => {
  const [{ routes, activeRouteId }] = useRoute()

  const activeRouteSteps = routes?.data?.[activeRouteId].steps

  return (
    <div className={styles.root}>
      {activeRouteSteps?.map((step, index) => (
        <StepButton
          activeRouteSteps={activeRouteSteps}
          onShowSteps={onShowSteps}
          key={step.id}
          {...{ step, index }}
        />
      ))}
    </div>
  )
}
