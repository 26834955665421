export const InstaSvg = ({ className }: { className: string }) => (
  <svg
    aria-label="Instagram Icon"
    className={className}
    fill="none"
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill-current"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24Zm24.001-12.8c-3.476 0-3.912.015-5.278.077-1.362.063-2.293.279-3.106.595a6.268 6.268 0 0 0-2.268 1.476 6.284 6.284 0 0 0-1.477 2.267c-.318.814-.534 1.745-.595 3.107-.061 1.365-.077 1.802-.077 5.278 0 3.476.016 3.911.077 5.277.063 1.362.279 2.293.595 3.106a6.271 6.271 0 0 0 1.476 2.268 6.268 6.268 0 0 0 2.267 1.477c.814.317 1.745.532 3.107.595 1.366.062 1.802.077 5.278.077 3.476 0 3.911-.015 5.276-.077 1.363-.063 2.294-.278 3.109-.595a6.263 6.263 0 0 0 2.266-1.477 6.284 6.284 0 0 0 1.477-2.267c.315-.814.53-1.745.595-3.107.061-1.365.077-1.8.077-5.277 0-3.476-.016-3.912-.077-5.278-.064-1.363-.28-2.293-.595-3.107a6.283 6.283 0 0 0-1.477-2.267 6.256 6.256 0 0 0-2.267-1.476c-.816-.316-1.747-.532-3.11-.595-1.365-.062-1.8-.077-5.277-.077h.004Z"
    />
    <path
      className="fill-current"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.853 13.507H24c3.418 0 3.823.012 5.172.073 1.249.057 1.926.266 2.377.441.597.232 1.023.51 1.471.957.448.448.725.875.958 1.473.175.45.384 1.127.44 2.375.062 1.35.075 1.755.075 5.171 0 3.416-.013 3.822-.074 5.17-.057 1.249-.266 1.926-.441 2.377a3.958 3.958 0 0 1-.958 1.47 3.958 3.958 0 0 1-1.47.958c-.452.176-1.13.384-2.377.44-1.35.062-1.755.075-5.173.075s-3.823-.013-5.172-.074c-1.248-.058-1.925-.266-2.377-.442a3.964 3.964 0 0 1-1.472-.957 3.967 3.967 0 0 1-.958-1.471c-.175-.45-.384-1.128-.44-2.376-.062-1.35-.074-1.755-.074-5.173s.012-3.821.073-5.17c.057-1.249.266-1.926.441-2.377.232-.598.51-1.024.958-1.472a3.973 3.973 0 0 1 1.472-.958c.451-.176 1.129-.384 2.377-.441 1.18-.054 1.638-.07 4.024-.072v.003Zm7.98 2.125a1.536 1.536 0 1 0 0 3.072 1.536 1.536 0 0 0 0-3.072Zm-6.832 1.795a6.574 6.574 0 1 0 0 13.147 6.574 6.574 0 0 0 0-13.147Z"
    />
    <path
      className="fill-current"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.001 19.733a4.267 4.267 0 1 1 0 8.534 4.267 4.267 0 0 1 0-8.534Z"
    />
  </svg>
)
