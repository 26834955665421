import * as Yup from 'yup'
import { UserVehicleType } from '@electro/consumersite/generated/graphql'

export interface RouteFormFieldsType {
  activeVehicle: UserVehicleType
  startingLocation: string
  waypoints: string[]
  destination: string
  startCharge: number
  endCharge: number
  electroverseOnly: boolean
  avoidTolls: boolean
}
export type RouteFormFieldsTypeKeys = keyof RouteFormFieldsType
export type RouteFormFieldsTypeValues = RouteFormFieldsType[RouteFormFieldsTypeKeys]

export const INITIAL_VALUES: RouteFormFieldsType = {
  activeVehicle: null,
  startingLocation: '',
  waypoints: [],
  destination: '',
  startCharge: 80,
  endCharge: 15,
  electroverseOnly: false,
  avoidTolls: false,
}

export const VALIDATION_SCHEMA = Yup.object().shape({
  activeVehicle: Yup.object()
    .shape({
      vehicle: Yup.object().shape({
        isHybrid: Yup.boolean().oneOf([false], 'map.route_planner.validation_error.no_hybrids'),
      }),
    })
    .required('map.route_planner.validation_error.no_vehicle')
    .nullable(),

  startingLocation: Yup.string().required('map.route_planner.validation_error.starting_location'),
  waypoints: Yup.array(Yup.string()),
  destination: Yup.string().required('map.route_planner.validation_error.destination'),

  startCharge: Yup.number()
    .required('map.route_planner.validation_error.start_charge')
    .min(10)
    .max(100),
  endCharge: Yup.number()
    .required('map.route_planner.validation_error.end_charge')
    .min(10)
    .max(100),
  electroverseOnly: Yup.boolean(),
  avoidTolls: Yup.boolean(),
})
