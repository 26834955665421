import { FeatureCollection } from 'geojson'
import { useMarkers } from '@electro/consumersite/src/components/Map/hooks'

import { Source, Layer } from 'react-map-gl'
import { MarkerNamesEnum } from '@electro/consumersite/src/components/Map/helpers/getMarkerIcon'
import { SymbolLayerSpecification } from 'mapbox-gl'

const { EJN_ACTIVE, ACTIVE } = MarkerNamesEnum

export const ActiveLocationLayer = () => {
  const { activeLocationMarker: marker } = useMarkers()

  const geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          isEjnLocation: marker?.isEjnLocation,
        },
        geometry: {
          type: 'Point',
          coordinates: marker?.coordinates,
        },
      },
    ],
  }

  const activeLocationLayerStyle: SymbolLayerSpecification = {
    id: 'point',
    source: 'active-location',
    slot: 'top',
    type: 'symbol',
    layout: {
      'icon-image': [
        'case',
        ['==', ['get', 'isEjnLocation'], ['boolean', false]],
        ACTIVE,
        ['==', ['get', 'isEjnLocation'], ['boolean', true]],
        EJN_ACTIVE,

        'DEFAULT',
      ],
      'icon-anchor': 'center',
      'icon-size': ['interpolate', ['linear'], ['zoom'], 9.0, 0.5, 22.0, 0.82],
    },
  }

  return marker ? (
    <Source id="active-location" type="geojson" data={geojson}>
      <Layer {...activeLocationLayerStyle} />
    </Source>
  ) : null
}
