import {
  ChargingLocationMetadataType,
  Speeds,
  Status,
} from '@electro/consumersite/generated/graphql'
import {
  mapCountryCodeToName,
  mapLocaleToCountryDetails,
} from '@electro/shared/utils/mapLocaleToCountryDetails'
import getLowestRatesfromEvses from '@electro/consumersite/src/components/Map/helpers/getLowestRatesFromEvses'

export interface LocationDetailsType {
  locationID?: number

  operator?: string
  isPartnered?: boolean

  country?: string
  /** If the charger is in a different country to the current browser locale setting */
  isInternational?: boolean

  connectorsTotal?: number
  speeds?: number[]
  maxSpeedType?: Speeds

  consumptionRate?: number
  timeRate?: number
  parkingTimeRate?: number
  connectionFee?: number

  chargersTotal?: number
  availablePercentage?: number
  occupiedPercentage?: number
  outOfOrderPercentage?: number
  unknownPercentage?: number
}

interface FormatLocationDetailsAnalyticsType {
  chargingLocation: ChargingLocationMetadataType
  locale: string
}

/** Creates useful analytics data based on a location. All fields optional as charger data can be very unreliable */
export const formatLocationDetailsAnalytics = ({
  chargingLocation,
  locale,
}: FormatLocationDetailsAnalyticsType): LocationDetailsType => {
  const analyticsData: LocationDetailsType = {
    locationID: parseInt(chargingLocation.chargingLocationPk, 10),
  }

  // Displays the operator name and if they're partnered with Electroverse
  if (chargingLocation?.operator?.name) analyticsData.operator = chargingLocation?.operator?.name
  if (chargingLocation?.operator)
    analyticsData.isPartnered = chargingLocation?.operator?.hasPartneredLocations ?? false

  if (chargingLocation?.country) {
    // Displays the country code as a readable name and determines if the charger is in another country to the users home
    analyticsData.country = mapCountryCodeToName(chargingLocation?.country)
    analyticsData.isInternational =
      chargingLocation?.country !== mapLocaleToCountryDetails(locale).alpha3
  }

  if (chargingLocation?.evses?.edges) {
    // Each EVSE can have multiple connectors, so we grab all their individual speeds and flatten them into a single array
    const speedArray = chargingLocation.evses.edges.flatMap((evseEdge) =>
      evseEdge?.node?.connectors?.edges?.map((connectorEdge) => connectorEdge?.node?.kilowatts),
    )

    analyticsData.connectorsTotal = speedArray.length
    analyticsData.speeds = speedArray

    // Determines the max speed type (currently shown on the map pin)
    if (speedArray.every((s) => s <= 7)) analyticsData.maxSpeedType = Speeds.Slow
    else if (speedArray.every((s) => s <= 22)) analyticsData.maxSpeedType = Speeds.Fast
    else if (speedArray.every((s) => s <= 50)) analyticsData.maxSpeedType = Speeds.Rapid
    else analyticsData.maxSpeedType = Speeds.Ultra

    // Lowest charging prices across all EVSEs (appears as a summary on the top of the panel)
    const lowestPrice = getLowestRatesfromEvses(chargingLocation.evses.edges)
    analyticsData.consumptionRate = lowestPrice?.consumptionRate?.unitAmount || 0
    analyticsData.timeRate = lowestPrice?.timeRate?.unitAmount || 0
    analyticsData.parkingTimeRate = lowestPrice?.parkingTimeRate?.unitAmount || 0
    analyticsData.connectionFee = lowestPrice?.connectionFee?.unitAmount || 0

    // Total chargers and their percentage breakdown of status (available/occupied/out of order/unknown)
    const chargerStatusArray = chargingLocation.evses.edges.map((evse) => evse.node.status)
    analyticsData.chargersTotal = chargerStatusArray.length

    analyticsData.availablePercentage =
      chargerStatusArray.filter((status) => status === Status.Available).length /
      chargerStatusArray.length

    analyticsData.occupiedPercentage =
      chargerStatusArray.filter((status) => status === Status.Charging).length /
      chargerStatusArray.length

    analyticsData.outOfOrderPercentage =
      chargerStatusArray.filter(
        (status) => status === Status.Outoforder || status === Status.Inoperative,
      ).length / chargerStatusArray.length

    analyticsData.unknownPercentage =
      chargerStatusArray.filter((status) => status === Status.Unknown).length /
      chargerStatusArray.length
  }

  return analyticsData
}
