import Image from 'next/image'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Typography } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'

const styles = {
  list: 'flex justify-around',
  captionedImage: {
    root: 'flex flex-col items-center basis-0 grow',
    caption: {
      root: 'text-xs text-center tracking-wide',
      text: 'font-medium mb-0',
    },
  },
  breakpoint: 'border-secondary',
  chargeSpeedInfoText: 'text-xs text-center',
}

export const KeyPanelContent = () => {
  const { t } = useTranslation('common')

  return (
    <>
      <div>
        <Typography variant="h3" as="h2">
          {t('map.key.heading.charge_point_speeds')}
        </Typography>
        <ul className={styles.list}>
          <CaptionedImage
            alt="Slow Charger Icon"
            src="/images/markers/key-speed-fast.png"
            caption={{ text: t('map.key.speed.slow'), subtext: '< 9kW' }}
            imgSize={50}
          />
          <CaptionedImage
            alt="Fast Charger Icon"
            src="/images/markers/key-speed-fast.png"
            caption={{ text: t('map.key.speed.fast'), subtext: '9 - 50kW' }}
            imgSize={50}
          />
          <CaptionedImage
            alt="Rapid Charger Icon"
            src="/images/markers/key-speed-rapid.png"
            caption={{ text: t('map.key.speed.rapid'), subtext: '51 - 150kW' }}
            imgSize={50}
          />
          <CaptionedImage
            alt="Ultra Rapid Charger Icon"
            src="/images/markers/key-speed-ultra-rapid.png"
            caption={{ text: t('map.key.speed.ultra_rapid'), subtext: '> 150kW' }}
            imgSize={50}
          />
        </ul>
        <Typography variant="small" className={styles.chargeSpeedInfoText}>
          {t('map.key.availability.explainer')}
        </Typography>
      </div>

      <hr className={styles.breakpoint} />

      <div>
        <Typography variant="h3" as="h2">
          {t('map.key.heading.availability')}
        </Typography>
        <ul className={styles.list}>
          <CaptionedImage
            alt="Available Marker Colour"
            src="/images/markers/marker-bg-available.png"
            caption={{ text: t('charge_point.availability.available') }}
          />
          <CaptionedImage
            alt="Occupied Marker Colour"
            src="/images/markers/marker-bg-occupied.png"
            caption={{ text: t('charge_point.availability.occupied') }}
          />
          <CaptionedImage
            alt="Out Of Order Marker Colour"
            src="/images/markers/marker-bg-out-of-order.png"
            caption={{ text: t('charge_point.availability.out_of_order') }}
          />
          <CaptionedImage
            alt="Unknown Marker Colour"
            src="/images/markers/marker-bg-unknown.png"
            caption={{ text: t('charge_point.availability.unknown') }}
          />
        </ul>
      </div>

      <hr className={styles.breakpoint} />

      <div>
        <Typography variant="h3" as="h2">
          {t('map.key.heading.access')}
        </Typography>
        <ul className={styles.list}>
          <CaptionedImage
            alt="Partnered with Electroverse Marker"
            src="/images/markers/ejn-fast-available.svg"
            caption={{ text: t('map.filters.electric_universe_only_toggle') }}
          />
          <CaptionedImage
            alt="Not Partnered with Electroverse Marker"
            src="/images/markers/marker-information-only.svg"
            caption={{ text: t('map.key.access.information_only') }}
            className="[&_img]:w-6 [&_img]:h-10"
          />
        </ul>
      </div>
    </>
  )
}

interface CaptionedImageProps {
  alt: string
  src: string
  imgSize?: number
  className?: string
  caption: {
    text: string
    subtext?: string
  }
}

const CaptionedImage = ({ alt, src, imgSize = 40, className, caption }: CaptionedImageProps) => (
  <li className={tw(styles.captionedImage.root, className)}>
    <Image alt={alt} src={src} width={imgSize} height={imgSize} />
    <div className={styles.captionedImage.caption.root}>
      <p className={styles.captionedImage.caption.text}>{caption.text}</p>
      {caption.subtext ? <p>{caption.subtext}</p> : null}
    </div>
  </li>
)
