import {
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useRef,
} from 'react'
import { useDebounce, useMount } from 'react-use'
import * as Sentry from '@sentry/nextjs'

import { useRouter } from 'next/router'
import { Skeleton } from '@electro/shared-ui-components'
import { useAuth } from '@electro/consumersite/src/hooks'
import {
  useThirdPartyAuthenticationMutation,
  useUserLazyQuery,
} from '@electro/consumersite/generated/graphql'
import {
  SIGN_UP_PASSWORD_REQUIRED_PARAM,
  SIGN_UP_VERIFICATION_TOKEN_PARAM,
} from '@electro/shared/constants'
import { handlePostLoginRedirect } from '@electro/consumersite/src/utils/handlePostLoginRedirect'

interface UseGoogleSignInProps {
  children: ReactNode | ReactNode[]
}

interface State {
  initialised: boolean
  loading: boolean
}

type UseGoogleSignIn = [state: State]

const GOOGLE_SIGN_IN_BUTTON_ID = 'gsi-button'

const UseGoogleSignInContext = createContext<UseGoogleSignIn>(null)

const GoogleSignIn = ({ children }) => <UseGoogleSignInProvider>{children}</UseGoogleSignInProvider>

interface ButtonProps {
  text?: 'signup_with' | 'signin_with' | 'continue_with' | 'signin'
  className?: string
}

function useGoogleSignInProvider(): UseGoogleSignIn {
  const [initialised, setInitialised] = useState<boolean>(false)
  const router = useRouter()
  const [{ sessionLoading }, { loginWithJwt }] = useAuth()
  const [fetchUser, { loading: fetchUserLoading }] = useUserLazyQuery()
  const [signInWithThirdParty, { loading }] = useThirdPartyAuthenticationMutation({
    onError: (error) => {
      Sentry.captureException(error)
    },
  })

  const handleCredentialResponse = useCallback(
    async (response) => {
      const variables = {
        idToken: response.credential,
        providerName: 'GOOGLE', // This value will be changed to an enum in the schema, for now we are simply hardcoding it.
      }

      const { data } = await signInWithThirdParty({ variables })

      /**
       * BE will only return relevant data if the user is logging in or signing up.
       * They are mutually exclusive.
       */
      const userIsLoggingIn =
        data.thirdPartyAuthentication?.login?.token &&
        data.thirdPartyAuthentication?.login?.refreshToken

      const userIsSigningUp = data.thirdPartyAuthentication?.signup?.token

      if (userIsLoggingIn) {
        await loginWithJwt({
          token: data.thirdPartyAuthentication?.login?.token,
          refreshToken: data.thirdPartyAuthentication?.login?.refreshToken,
        })

        const { data: userData } = await fetchUser()
        handlePostLoginRedirect({ userData, router })
      } else if (userIsSigningUp) {
        /**
         * If we have a new user signing up we want to redirect them to the verification page
         * where we collect the remaining data that is required for sign up. We set a URL param
         * to indicate that the user does not need to set a password.
         */
        router.push(
          `/sign-up/magic/verify?${SIGN_UP_VERIFICATION_TOKEN_PARAM}=${data.thirdPartyAuthentication?.signup?.token}&${SIGN_UP_PASSWORD_REQUIRED_PARAM}=false`,
        )
      }
    },
    [fetchUser, loginWithJwt, router, signInWithThirdParty],
  )

  const initializeGsi = () => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.NEXT_PUBLIC_GOOGLE_SIGN_IN_CLIENT_ID,
        callback: handleCredentialResponse,
      })
      setInitialised(true)
    }
  }

  const loadGoogleSignInScript = (onLoadCallback: () => void) => {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    script.onload = onLoadCallback
    document.body.appendChild(script)
  }

  useMount(() => {
    loadGoogleSignInScript(initializeGsi)
  })

  const state = useMemo(
    () => ({
      initialised,
      loading: sessionLoading || loading || fetchUserLoading,
    }),
    [fetchUserLoading, initialised, loading, sessionLoading],
  )

  return [state]
}

export const UseGoogleSignInProvider = ({ children }: UseGoogleSignInProps) => {
  const ctx = useGoogleSignInProvider()
  return <UseGoogleSignInContext.Provider value={ctx}>{children}</UseGoogleSignInContext.Provider>
}

export const useGoogleSignIn = (): UseGoogleSignIn => {
  const context = useContext(UseGoogleSignInContext)
  if (!context)
    throw new Error('useGoogleSignIn() cannot be used outside of <UseGoogleSignInProvider/>')
  return context
}

const Prompt = () => {
  const router = useRouter()
  const [{ initialised }] = useGoogleSignIn()
  const [{ session, sessionLoading }] = useAuth()

  const showPrompt = useMemo(() => {
    // paths where we don't want to trigger the google sign in prompt.
    const BLACKLISTED_PATHS = [
      '/sign-up/*',
      '/log-in/*',
      '/user/*',
      '/where-in-the-universe/*',
      '/electrocard/*',
      '/map/all/*',
      '/map/embed/*',
      '/operators/*',
    ]
    const pathAllowed = !BLACKLISTED_PATHS.some((path) => router.pathname.match(path))

    return pathAllowed && !session && !sessionLoading && initialised
  }, [initialised, router.pathname, session, sessionLoading])

  useEffect(() => {
    if (showPrompt && window.google) {
      /**
       * Triggers a 'sign in with google' modal across the website.
       * The callback for this is handled by handleCredentialResponse()
       * in the useGoogleSignInProvider hook.
       */
      window.google.accounts.id.prompt()
    }
  }, [showPrompt])
  return null
}

const Button = ({ text = 'continue_with', className }: ButtonProps) => {
  const { locale } = useRouter()
  const [{ initialised }] = useGoogleSignIn()
  const gsiButtonRef = useRef<HTMLDivElement>(null)
  const [buttonWidth, setButtonWidth] = useState(400)
  const [resizing, setResizing] = useState(false)

  const sharedButtonProps = useMemo(
    () => ({
      theme: 'outline',
      size: 'large',
      text,
      shape: 'pill',
      locale,
    }),
    [locale, text],
  )

  useDebounce(
    () => {
      /**
       * Debouncing the button width so we don't trigger re-renders on every resize event.
       */
      if (window.google && initialised) {
        window.google.accounts.id.renderButton(document.getElementById(GOOGLE_SIGN_IN_BUTTON_ID), {
          ...sharedButtonProps,
          width: `${buttonWidth}`,
        })
      }
    },
    200,
    [buttonWidth],
  )

  useEffect(() => {
    let resizeObserver: ResizeObserver | null = null

    /**
     * We need to observe the button's width to ensure that the Google Sign In button
     * is always rendered with the correct width. We're doing this because the button
     * does not come with an auto-resize feature.
     */
    if (gsiButtonRef.current) {
      resizeObserver = new ResizeObserver((entries) => {
        if (entries[0].contentRect.width !== buttonWidth) {
          setResizing(true)
          setButtonWidth(entries[0].contentRect.width)
        }
      })
      resizeObserver.observe(gsiButtonRef.current)
    }

    return () => {
      if (resizeObserver) {
        setResizing(false)
        resizeObserver.disconnect()
      }
    }
  }, [buttonWidth, initialised, locale, sharedButtonProps, text, resizing])

  return !resizing && initialised ? (
    <div
      id={GOOGLE_SIGN_IN_BUTTON_ID}
      // setting !important here to prevent a small layout shift in the button when it's rendered.
      className={`!h-11 !overflow-hidden ${className}`}
      ref={gsiButtonRef}
    >
      <Skeleton variant="circular" height={40} />
    </div>
  ) : (
    <Skeleton variant="circular" className={`w-full !h-11 ${className}`} height={40} />
  )
}

GoogleSignIn.Button = Button
GoogleSignIn.Prompt = Prompt

export { GoogleSignIn }
