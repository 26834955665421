import {
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
  ReactNode,
  useEffect,
} from 'react'
import { useAuth } from '@electro/consumersite/src/hooks'
import {
  DEFAULT_SIDEBAR_PANELS,
  DefaultSidebarPanelsType,
  SidebarPanelsType,
} from '@electro/consumersite/src/components/Map/types'

interface State {
  visiblePanels: SidebarPanelsType[]
  disabledPanels: SidebarPanelsType[]
  hiddenPanels: SidebarPanelsType[]
}

interface Handlers {
  togglePanelVisibility: (key: SidebarPanelsType) => void
  showPanelExclusive: (key: SidebarPanelsType) => void
  setHiddenPanels: (panels: SidebarPanelsType[]) => void
}

type UseMapSidebar = [state: State, handlers: Handlers]
const UseMapSidebarContext = createContext<UseMapSidebar>(null)

function useMapSidebarProvider({ panels }: { panels: DefaultSidebarPanelsType }): UseMapSidebar {
  const [{ session }] = useAuth()
  const [visiblePanels, setVisiblePanels] = useState<SidebarPanelsType[]>(panels?.visible ?? [])
  const [disabledPanels, setDisabledPanels] = useState<SidebarPanelsType[]>(panels?.disabled ?? [])
  const [hiddenPanels, setHiddenPanels] = useState<SidebarPanelsType[]>(panels?.hidden ?? [])

  /** Disable some panels if the user is not logged in */
  useEffect(() => setDisabledPanels(session ? [] : DEFAULT_SIDEBAR_PANELS.disabled), [session])

  /** Toggle the visibility of a panel without changing the visible state of other panels */
  const togglePanelVisibility = useCallback(
    (key: SidebarPanelsType) => {
      if (disabledPanels.includes(key) || hiddenPanels.includes(key)) return
      setVisiblePanels((existingPanels) => {
        const updatedPanels = existingPanels.includes(key)
          ? existingPanels.filter((panel) => panel !== key)
          : [...existingPanels, key]

        const allPanelsClosed = updatedPanels.length === 0
        return allPanelsClosed ? ['map'] : updatedPanels
      })
    },
    [disabledPanels, hiddenPanels],
  )

  /** Toggle the visibility of a panel while resetting the visible state of other panels */
  const showPanelExclusive = useCallback(
    (key: SidebarPanelsType) => {
      if (disabledPanels.includes(key) || hiddenPanels.includes(key)) return
      setVisiblePanels(() => [key])
    },
    [disabledPanels, hiddenPanels],
  )

  const state = useMemo(
    () => ({ visiblePanels, disabledPanels, hiddenPanels }),
    [visiblePanels, disabledPanels, hiddenPanels],
  )

  const handlers = useMemo(
    () => ({ togglePanelVisibility, showPanelExclusive, setHiddenPanels }),
    [togglePanelVisibility, showPanelExclusive, setHiddenPanels],
  )

  return [state, handlers]
}

interface UseMapSidebarProps {
  children: ReactNode | ReactNode[]
  panels?: DefaultSidebarPanelsType
}

export const UseMapSidebarProvider = ({ children, panels }: UseMapSidebarProps) => {
  const ctx = useMapSidebarProvider({ panels })
  return <UseMapSidebarContext.Provider value={ctx}>{children}</UseMapSidebarContext.Provider>
}

export const useMapSidebar = (): UseMapSidebar => {
  const context = useContext(UseMapSidebarContext)
  if (!context)
    throw new Error('useMapSidebar() cannot be used outside of <UseMapSidebarProvider/>')
  return context
}
