/**
 * Map markers icons are hosted in Mapbox and referenced in layer styles.
 * If you need to update them you will have to do it remotley via Mapbox studio.
 *
 * The exception is <SearchMarkerLayer/> which is a react component rendered on top of the map.
 */

import {
  SearchMarkerLayer,
  RouteLayer,
  LocationsLayer,
  ActiveLocationLayer,
  GhostRouteLayer,
} from '@electro/consumersite/src/components/Map/components/MapLayers/layers'

export const MapLayers = () => (
  <>
    <ActiveLocationLayer />
    <SearchMarkerLayer />
    <GhostRouteLayer />
    <RouteLayer />
    <LocationsLayer />
  </>
)
