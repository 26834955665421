import Image from 'next/image'
import { Button } from '@electro/shared-ui-components'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { useMapFilters } from '@electro/consumersite/src/components/Map/hooks'
import { MapFiltersEnum } from '@electro/consumersite/src/components/Map/types'
import { ConnectorNames, mapConnectorNameToIcon } from '@electro/consumersite/src/helpers'
import { FiltersVehicleSelector } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/Filters/components'
// eslint-disable-next-line import/no-unresolved
import { mapFiltersOptions } from '@electro/consumersite/generated/mapBuildData'

const styles = {
  heading: 'mb-2',
  container: 'flex flex-wrap gap-2 mt-4',
  socket: {
    root: 'flex flex-col items-center',
    button: {
      root: 'flex flex-col items-center w-[69px] h-16 gap-y-1 rounded-lg bg-base/70',
      active: 'bg-secondary/60 hover:bg-secondary/80',
      icon: 'w-7 h-7',
    },
    label: 'text-[10px] font-medium tracking-wide mt-0.5 mb-0',
  },
}

const { SOCKET_TYPES } = MapFiltersEnum

/** Filterable vehicle sockets for chargers, with a vehicle selector button for logged in users */
export const FiltersSocketTypes = () => {
  const { t } = useTranslation('common')
  const [{ currentFilters }, { updateFilters }] = useMapFilters()

  return (
    <div data-testid="filters-socket-types">
      <h4 className={styles.heading}>{t('map.filters.subtitles.socket_types')}</h4>

      <FiltersVehicleSelector />

      <div className={styles.container}>
        {mapFiltersOptions.connectors.map((socketType: ConnectorNames) => {
          const isActive = currentFilters[SOCKET_TYPES].includes(socketType)

          /** Adds/Removes the socket from the current filters */
          const onButtonClick = () => {
            const updatedSockets = isActive
              ? currentFilters[SOCKET_TYPES].filter((socket) => socket !== socketType)
              : [...currentFilters[SOCKET_TYPES], socketType]

            updateFilters({ [SOCKET_TYPES]: updatedSockets.sort() })
          }

          return (
            <div key={socketType} className={styles.socket.root}>
              <Button
                size="2xs"
                onClick={onButtonClick}
                overrideDisabledInteractivity
                className={tw({
                  [styles.socket.button.root]: true,
                  [styles.socket.button.active]: isActive,
                })}
              >
                <Image
                  src={mapConnectorNameToIcon(socketType)}
                  className={styles.socket.button.icon}
                  alt={socketType}
                  height={24}
                  width={24}
                />

                <p className={styles.socket.label}>
                  {socketType === 'Other' ? t('map.filters.other_socket_type') : socketType}
                </p>
              </Button>
            </div>
          )
        })}
      </div>
    </div>
  )
}
