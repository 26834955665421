import { HTMLAttributes } from 'react'
import { Transition } from '@headlessui/react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useMapSearch } from '@electro/consumersite/src/components/Map/hooks'

const styles = {
  root: {
    all: 'absolute -mt-11 w-full z-[35] flex flex-col ring-2 ring-transparent pointer-events-none',
    map: 'pr-3 md:pr-0 md:max-w-sm',
    routePlanner: 'pr-4 max-w-[calc(100%-theme(spacing.1))]',
  },
  verticalSlices: {
    centre: 'bg-base w-full h-1',
    outer: {
      root: 'w-full h-5',
      bottom: 'bg-base border-t-0 rounded-b-full',
    },
  },
  content: 'z-20 flex flex-col outline-none px-2 pointer-events-auto',
}

interface SearchItemsContainerProps extends HTMLAttributes<HTMLDivElement> {
  show?: boolean
}

/** Utilises 9-slice scaling to create a clean animation: https://pqina.nl/blog/animating-width-and-height-without-the-squish-effect/ */
export const SearchItemsContainer = ({ children, show }: SearchItemsContainerProps) => {
  const [{ target }] = useMapSearch()

  return (
    <div className={tw(styles.root.all, styles.root[target])}>
      <div className={tw(styles.verticalSlices.outer.root)} />
      <Transition
        show={show}
        unmount={false}
        className={styles.verticalSlices.centre}
        enter="transition-transform ease-in-out duration-300"
        enterFrom="origin-top scale-y-[1]"
        enterTo="origin-top scale-y-[65]" // Hardcoded value aligns to content height of 5 items
        leave="transition-transform ease-in-out duration-300"
        leaveFrom="origin-top scale-y-[65]"
        leaveTo="origin-top scale-y-[1]"
      />
      <Transition
        show={show}
        unmount={false}
        className={tw(styles.verticalSlices.outer.root, styles.verticalSlices.outer.bottom)}
        enter="transition-transform ease-in-out duration-300 origin-top"
        enterFrom="translate-y-0"
        enterTo="translate-y-[255px]" // Hardcoded value aligns to content height of 5 items
        leave="transition-transform ease-in-out duration-300 origin-top"
        leaveFrom="translate-y-[255px]"
        leaveTo="translate-y-0"
      />

      <Transition
        show={show}
        unmount={false}
        className={styles.content}
        enter="transition-opacity ease-in-out duration-200 delay-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition>
    </div>
  )
}
