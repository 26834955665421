export const ConstantinesGhostSvg = ({ className }: { className?: string }) => (
  <svg
    aria-label="constantines-ghost"
    className={className}
    width="617"
    height="678"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M533.214 483.153c8.965-41.919 74.33-102.939 82.174-184.622C625.697 191.133 571.238.491 304.922.491 38.606.491.06 197.004.06 279.58c0 109.628 80.828 166.337 77.168 205.358l.747.669c2.166 41.993-42.282 44.594-40.04 70.682 2.165 20.588 59.313 25.047 99.354 3.716a77.784 77.784 0 0 1-25.922 29.73c-20.842 11-37.351 16.871-37.351 29.729 0 12.859 61.555 44.595 124.604 13.23a154.678 154.678 0 0 0 42.282-28.689c0 2.23.747 4.385.747 5.872 2.988 17.689 13.372 67.709 59.314 67.709s55.131-44.892 62.526-70.98a20.71 20.71 0 0 0 1.494-6.614 169.863 169.863 0 0 0 45.27 31.662c63.05 30.919 124.605-.744 124.605-13.23 0-12.487-16.285-17.689-37.352-29.73a75.029 75.029 0 0 1-27.49-30.919c40.041 24.304 100.923 19.102 103.09-1.486 2.241-26.46-44.822-28.689-40.041-73.581 2.54-.892-.598.446.149.446Z"
      className=" fill-current text-current bg-current"
    />
  </svg>
)
