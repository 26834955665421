import useSWR from 'swr'
import Image, { ImageProps } from 'next/image'
import { ForwardedRef, Suspense, forwardRef, lazy } from 'react'
import { LottieComponentProps } from 'lottie-react'

const LazyLottieComponent = lazy(() => import('lottie-react'))

interface AnimatedComponentProps {
  name: string
  getAnimationData: () => Promise<any>
  lottieProps?: Omit<LottieComponentProps, 'animationData' | 'ref'>
  imageProps?: ImageProps
}

/**
 * IMPORTANT: This component has resulted in some massive performance deficits and so been refactored a few times.
 * Please be careful when making changes. Check the performance impact by running `ANALYZE=true yarn dev`
 * and comparing with results on the master branch.
 *
 * This implementation lazy loads the Lottie library and utilises SWR to lazy load the animation data.
 *
 * For animations which should be visible on page load, it is recommended to add an image fallback of
 * the animation default state. See apps/consumersite/pages/404.tsx for an example.
 */
export const AnimatedComponent = forwardRef(
  (
    { getAnimationData, lottieProps, imageProps, name }: AnimatedComponentProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { data } = useSWR(name, async () => {
      import('lottie-react') // Trigger the library lazy load even if the animationData is not ready
      return getAnimationData()
    })

    if (!data) {
      if (imageProps) return <Image {...imageProps} />
      return null
    }

    return (
      <Suspense fallback={imageProps ? <Image {...imageProps} /> : undefined}>
        <LazyLottieComponent animationData={data} data-testid={name} ref={ref} {...lottieProps} />
      </Suspense>
    )
  },
)
