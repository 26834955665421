import { useEffect, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { Source, Layer } from 'react-map-gl'
import { LineLayerSpecification } from 'mapbox-gl'
import { Position, lineChunk, length } from '@turf/turf'
import electroTheme from '@electro/shared/theme/electro'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import {
  useMapSidebar,
  useRoute,
  useRoutePlannerForm,
} from '@electro/consumersite/src/components/Map/hooks'

const dashedLineStyle: LineLayerSpecification = {
  id: 'line-animation',
  type: 'line',
  source: 'line',
  slot: 'top',
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-color': electroTheme.extend.colors.primary.DEFAULT,
    'line-width': 5,
    'line-dasharray': [0, 3, 2],
  },
}

/** Displays a dashed line as the crow flies between route locations during form entry */
export const RouteFormLayer = () => {
  const [{ routes }] = useRoute()
  const [{ visiblePanels }] = useMapSidebar()
  const [{ dashedRouteLine }] = useRoutePlannerForm({})
  const [coordinatesList, setCoordinatesList] = useState<Position[]>()
  const [currentLine, setCurrentLine] = useState<typeof dashedRouteLine>()

  /** Divide the line coordinates into small chunks. Store these in state and set the line without coordinates */
  useEffect(() => {
    try {
      const routeLength = length(dashedRouteLine)
      const routeSplit = lineChunk(dashedRouteLine, routeLength / 1000)

      setCoordinatesList(routeSplit.features.map((feature) => feature.geometry.coordinates[0]))
      setCurrentLine({ ...dashedRouteLine, geometry: { type: 'LineString', coordinates: [] } })
    } catch {
      setCoordinatesList(null)
      setCurrentLine(null)
    }
  }, [dashedRouteLine])

  /** Every 1ms add a new coordinate from the list to the line - provides a fade in animation effect */
  useUpdateEffect(() => {
    if (!coordinatesList || !currentLine) return
    if (currentLine.geometry.coordinates.length === coordinatesList.length) return

    const coordinates = coordinatesList.slice(0, currentLine.geometry.coordinates.length + 1)
    const updatedLine = { ...currentLine, geometry: { ...currentLine.geometry, coordinates } }
    setTimeout(() => setCurrentLine(updatedLine), 1)
  }, [currentLine, coordinatesList])

  if (!dashedRouteLine || routes || !visiblePanels.includes(SidebarPanels.ROUTE_PLANNER))
    return null

  return (
    <Source id="route-form-dashed-line" type="geojson" data={currentLine}>
      <Layer {...dashedLineStyle} />
    </Source>
  )
}
