import { useState } from 'react'
import { useMount } from 'react-use'
import { Source, Layer } from 'react-map-gl'
import { CircleLayerSpecification } from 'mapbox-gl'
import { useMapFilters } from '@electro/consumersite/src/components/Map/hooks'
import { operatorsLayerStyle } from '@electro/consumersite/src/components/Map/components/MapLayers/layers'

/**
 * This layer is for use on the operators map on the route `/operators`
 * It is not exported by the <Map/> compound component.
 */
export const OperatorsLayer = ({ electroverseOperators }) => {
  const [{ currentFilters }] = useMapFilters()
  const operatorsFilters = currentFilters?.operators
    .map(({ pk }) => `operator_groups[]=${pk}`)
    .join('&')

  const [operatorsStyle, setOperatorsStyle] = useState<CircleLayerSpecification>()
  useMount(async () => setOperatorsStyle(await operatorsLayerStyle(electroverseOperators)))

  return (
    <Source
      id="operator_locations"
      type="vector"
      tiles={[
        `${process.env.NEXT_PUBLIC_EJN_API_REST_ENDPOINT}locations/tiles/elastic/{z}/{x}/{y}?${operatorsFilters}`,
      ]}
    >
      <Layer {...operatorsStyle} />
    </Source>
  )
}
