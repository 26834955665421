import Image from 'next/image'
import { Dispatch, SetStateAction } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { SwitchToggle } from '@electro/shared-ui-components'
import { ElectroversePartnerBoltIcon } from '@electro/consumersite/src/icons'

const styles = {
  partneredInfoBubble: {
    root: 'flex items-center gap-x-2 bg-secondary-dark p-2 rounded-2xl text-xs [&_p]:m-0',
    text: 'text-xs mb-0',
  },
  partneredToggle: {
    root: 'flex items-center justify-between mx-2 [&>h4]:leading-4',
    icon: '!p-[2px]',
  },
}

interface OperatorListFiltersProps {
  showPartneredOnly: boolean
  setShowPartneredOnly: Dispatch<SetStateAction<boolean>>
}

/** Filter options for the list of operators, applies to both selected and unselected */
export const OperatorListFilters = ({
  showPartneredOnly,
  setShowPartneredOnly,
}: OperatorListFiltersProps) => {
  const { t } = useTranslation('common')

  return (
    <>
      <span className={styles.partneredInfoBubble.root}>
        <Image
          src="/images/ElectroversePartnerBoltCircle.svg"
          alt="Electroverse Partner"
          height={19}
          width={19}
        />

        <p className={styles.partneredInfoBubble.text}>
          <strong>{t('map.operator_list.lightning_bolt')}</strong>{' '}
          {t('map.operator_list.partnered_operator_description')}
        </p>
      </span>

      <span
        className={styles.partneredToggle.root}
        data-testid="operator-list-partners-only-toggle"
      >
        <h4>{t('map.operator_list.electroverse_partners_only')}</h4>

        <SwitchToggle
          checked={showPartneredOnly}
          onChange={() => setShowPartneredOnly(!showPartneredOnly)}
          thumbIcon={<ElectroversePartnerBoltIcon className={styles.partneredToggle.icon} />}
        />
      </span>
    </>
  )
}
