import { useRouter } from 'next/router'
import Image from 'next/image'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import {
  selectRegionalisedBlogLink,
  BlogTitleEnum,
} from '@electro/consumersite/src/helpers/selectLocalisedBlog'
import { Locale } from '@electro/shared/types/locales'
import { CountryCodes } from '@electro/shared/types/countries'

import { useUserQuery } from '@electro/consumersite/generated/graphql'
import { Button, Typography } from '@electro/shared-ui-components'
import { usePlugAndChargeSignup } from '@electro/consumersite/src/components/PlugAndChargeSignup/hooks'
import { PncStagesEnum } from '@electro/consumersite/src/components/PlugAndChargeSignup/types'
import { SocialMediaPlatformLinksMap } from '@electro/consumersite/src/components/Footer'

export const ContractCreationErrorStage = () => {
  const [{ vehicle }, { setActiveStage }] = usePlugAndChargeSignup()
  const router = useRouter()
  const { t } = useTranslation('common')
  const { data: userData } = useUserQuery()

  const handleTryAgain = () => {
    const initialActiveStage =
      userData?.me?.countryCode === CountryCodes.GBR
        ? PncStagesEnum.VEHICLE_REG_LOOKUP
        : PncStagesEnum.ENTER_VEHICLE_VIN
    setActiveStage(initialActiveStage)
  }

  const handleExit = () => {
    router.push('/')
  }

  const electroverseEmailLink = SocialMediaPlatformLinksMap[router.locale].email

  return (
    <>
      <Typography variant="h1" as="h1">
        {t('plug_and_charge.contract_creation_error.title')}
      </Typography>
      {vehicle?.regNumber && (
        <Typography as="h2" className="text-2xl font-medium mb-4">
          {vehicle?.regNumber}{' '}
        </Typography>
      )}

      <Image
        src="/images/mr-zap-oh-no.png"
        alt="Mr Zap looking very sad because he has dropped his ice cream!"
        width="334"
        height="243"
        className="mx-auto"
      />
      <Typography variant="p" as="p">
        {t('plug_and_charge.contract_creation_error.subtitle')}
      </Typography>

      {/* Plug & Charge compatible vehicle */}
      {vehicle?.info.isPncCompatible && (
        <Typography variant="p" as="p">
          {t('plug_and_charge.contract_creation_error.incorrect_software_suggestion')}
        </Typography>
      )}

      {/* Assuming that because there's no vehicle in memory that the user came from the VIN number stage */}
      {!vehicle && (
        <Typography variant="p" as="p">
          {t('plug_and_charge.contract_creation_error.incorrect_pcid_suggestion')}
        </Typography>
      )}

      <Typography variant="p" as="p">
        <Link
          href={selectRegionalisedBlogLink(
            router.locale as Locale,
            BlogTitleEnum.PLUG_AND_CHARGE_BLOG,
          )}
        >
          {t('plug_and_charge.contract_creation_error.need_help')}
        </Link>
      </Typography>

      <Typography variant="p" as="p">
        <Trans
          i18nKey="common:plug_and_charge.contract_creation_error.contact_us"
          components={{
            electroverseEmail: (
              <a href={electroverseEmailLink}>{electroverseEmailLink.split(':')[1]}</a>
            ),
          }}
        />{' '}
        {t('plug_and_charge.contract_creation_error.love_to_hear_from_you')}
      </Typography>

      <Button className="mt-4" fullWidth onClick={handleTryAgain}>
        {t('utility.try_again')}
      </Button>
      <Button className="mt-4" variant="naked" fullWidth onClick={handleExit}>
        {t('common.button.electroverse_home')}
      </Button>
    </>
  )
}
