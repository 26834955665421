import useTranslation from 'next-translate/useTranslation'
import { UseMapSearchProvider } from '@electro/consumersite/src/components/Map/hooks'
import { LocationSearch } from '@electro/consumersite/src/components/Map/components/MapLocationSearch/components'

const styles = {
  root: 'px-1.5 md:px-0 md:max-w-sm',
}

export const MapLocationSearchV2 = () => {
  const { t } = useTranslation('common')

  return (
    <UseMapSearchProvider target="map" placeholder={t('map.search.placeholder')}>
      <LocationSearch className={styles.root} />
    </UseMapSearchProvider>
  )
}
