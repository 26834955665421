import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { EJN_AUTH_TOKEN_KEY } from '@electro/shared/constants'
import { ACCOUNT_ID } from '@electro/consumersite/src/constants/localStorage'

const createAuthLink = () =>
  setContext((_, { headers }) => {
    let nextHeaders
    if (window !== undefined && window?.localStorage) {
      const token = localStorage.getItem(EJN_AUTH_TOKEN_KEY)
      nextHeaders = { ...headers }
      if (token) {
        nextHeaders.authorization = `JWT ${token}`
      }
    }
    return {
      headers: {
        ...nextHeaders,
      },
    }
  })

const accountTypeLink = () =>
  setContext((_, { headers }) => {
    let nextHeaders
    if (window !== undefined && window?.localStorage && window?.localStorage?.getItem(ACCOUNT_ID)) {
      const accountId = window?.localStorage?.getItem(ACCOUNT_ID)
      nextHeaders = { ...headers, 'X-Account-Id': accountId }
    }
    return {
      headers: {
        ...nextHeaders,
      },
    }
  })

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_EJN_API_ENDPOINT,
  headers: {
    'Api-key': process.env.NEXT_PUBLIC_EJN_API_KEY,
    'Accept-Encoding': 'gzip, deflate, br',
    source: 'web',
  },
})

const additiveLink = from([accountTypeLink(), createAuthLink(), httpLink])

export const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache(),
})

/**
 * Work around to call apollo client in
 * getServerSideProps without auth!
 */
export const serverSideClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
})
