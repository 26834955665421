import { v1 as uuid } from 'uuid'
import { Locale } from '@electro/shared/types/locales'
import { Menu } from '@electro/shared-ui-components'
import { ReactNode, useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'

interface OctoRegionSelectProps {
  children: ReactNode | ReactNode[]
}

interface RegionOption {
  label: string
  flag: string
  url: string
  assumedLocale?: Locale
  id: string
}

const REGION_OPTIONS: RegionOption[] = [
  {
    label: 'Octopus Energy GB',
    flag: '🇬🇧',
    url: 'https://octopus.energy',
    assumedLocale: 'en-GB',
    id: uuid(),
  },
  {
    label: 'Octopus Energy FR',
    flag: '🇫🇷',
    url: 'https://octopusenergy.fr',
    assumedLocale: 'fr',
    id: uuid(),
  },
  {
    label: 'Octopus Energy DE',
    flag: '🇩🇪',
    url: 'https://octopusenergy.de',
    assumedLocale: 'de',
    id: uuid(),
  },
  {
    label: 'Octopus Energy ES',
    flag: '🇪🇸',
    url: 'https://octopusenergy.es',
    assumedLocale: 'es',
    id: uuid(),
  },
  {
    label: 'Octopus Energy IT',
    flag: '🇮🇹',
    url: 'https://octopusenergy.it',
    assumedLocale: 'it',
    id: uuid(),
  },
  {
    label: 'Octopus Energy JP',
    flag: '🇯🇵',
    url: 'https://octopusenergy.co.jp',
    assumedLocale: null,
    id: uuid(),
  },
  {
    label: 'Octopus Energy NZ',
    flag: '🇳🇿',
    url: 'https://octopusenergy.nz',
    assumedLocale: null,
    id: uuid(),
  },
  {
    label: 'Octopus Energy US',
    flag: '🇺🇸',
    url: 'https://octopusenergy.com',
    assumedLocale: null,
    id: uuid(),
  },
]

export const OctoRegionSelect = ({ children }: OctoRegionSelectProps) => {
  const router = useRouter()
  const placeCurrentLocaleFirst = useCallback(() => {
    const currentLocale = REGION_OPTIONS.find((option) => option.assumedLocale === router?.locale)
    const otherLocales = REGION_OPTIONS.filter((option) => option.assumedLocale !== router?.locale)
    return [currentLocale, ...otherLocales]
  }, [router])

  const menuItems = useMemo(() => placeCurrentLocaleFirst(), [placeCurrentLocaleFirst])

  const handleMenuClick = useCallback(
    (url: string) => () => {
      window.open(url, '_blank')
    },
    [],
  )

  return (
    <Menu data-testid="octo-regions-menu">
      <Menu.Button chevron={false}>{children}</Menu.Button>
      <Menu.MenuItemList>
        {menuItems.map((item) => (
          <Menu.MenuItem key={item.id} onSelect={handleMenuClick(item?.url)}>
            {`${item?.flag} ${item?.label}`}
          </Menu.MenuItem>
        ))}
      </Menu.MenuItemList>
    </Menu>
  )
}
