import { useRouter } from 'next/router'
import { Locales } from '@electro/shared/types/locales'
import useTranslation from 'next-translate/useTranslation'
import {
  SocialMediaPlatformLinksMap,
  SocialMediaPlatformsEnum,
} from '@electro/consumersite/src/components'

const styles = {
  root: 'text-sm text-center px-4',
  text: 'mb-0',
  email: 'font-semibold text-primary',
}

/** Displays a localised support email for users who require a response */
export const FeedbackSupportEmail = () => {
  const { locale } = useRouter()
  const { t } = useTranslation('common')

  const localisedSupportEmailLink =
    SocialMediaPlatformLinksMap[locale][SocialMediaPlatformsEnum.EMAIL] ??
    SocialMediaPlatformLinksMap[Locales.EN][SocialMediaPlatformsEnum.EMAIL]

  const localisedSupportEmail = localisedSupportEmailLink.replace('mailto:', '')

  return (
    <div className={styles.root}>
      <p className={styles.text}>{t('map.feedback.support_email')}</p>
      <a href={localisedSupportEmailLink} className={styles.email} target="_blank" rel="noreferrer">
        {localisedSupportEmail}
      </a>
    </div>
  )
}
