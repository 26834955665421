import { Modal, LoadingBolt, Typography, Button } from '@electro/shared-ui-components'
import Image from 'next/image'

import { RoutePlannerForm } from '@electro/consumersite/src/components/Map/components/RoutePlanner/components'
import { useRoute } from '@electro/consumersite/src/components/Map/hooks'
import { useAuth } from '@electro/consumersite/src/hooks'

import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

export const RoutePlannerModalScreen = () => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const [{ loading, routeFormFields }, { handleNewRouteSubmit }] = useRoute()
  const [{ session }] = useAuth()

  return (
    <>
      {!loading && <Modal.CloseButton />}
      {!session && (
        <>
          <Modal.Body>
            <div className="text-center text-sm">
              <Image
                alt="route planner illustration"
                src="/images/mr-zap-route.png"
                width={418}
                height={190}
              />
              {t('map.route_planner.logged_out')
                .split('\\n')
                .map((line) => (
                  <Typography variant="body" as="p" key={line}>
                    {line}
                  </Typography>
                ))}
            </div>
          </Modal.Body>
          <Modal.Actions stacked>
            <Button fullWidth onClick={() => router.push('/log-in')}>
              {t('user.log_in')}
            </Button>
            <Button fullWidth variant="outline" onClick={() => router.push('/sign-up/magic')}>
              {t('new_onboarding_signup_button')}
            </Button>
          </Modal.Actions>
        </>
      )}
      {session && (
        <RoutePlannerForm formFields={routeFormFields} onSubmit={handleNewRouteSubmit}>
          {!loading && (
            <Modal.Header>
              <div className="flex justify-between">{t('map.route_planner.title')}</div>
            </Modal.Header>
          )}
          <Modal.Body>
            {loading && <LoadingBolt subtitle={t('utility.loading_message')} className="my-16" />}
            {!loading && <RoutePlannerForm.Fields />}
          </Modal.Body>
          {!loading && (
            <Modal.Actions>
              <RoutePlannerForm.SubmitButton />
            </Modal.Actions>
          )}
        </RoutePlannerForm>
      )}
    </>
  )
}
