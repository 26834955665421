import { Button } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { ChevronRightIcon } from '@heroicons/react/16/solid'
import { useMapFilters, useMapSidebar } from '@electro/consumersite/src/components/Map/hooks'
import { MapFiltersEnum, SidebarPanels } from '@electro/consumersite/src/components/Map/types'

const styles = {
  root: 'flex flex-row justify-between shrink-0 py-1 px-4 [&>span]:-ml-4 rounded-lg hover:bg-secondary/10',
  label: {
    root: 'flex flex-col text-xs text-left leading-4 mr-6 [&>*]:m-0',
    title: 'text-primary-light font-semibold',
  },
  icon: 'w-8 -mr-1 text-white/90',
}

const { ELECTROVERSE_COMPATIBLE, OPERATORS } = MapFiltersEnum

/** Button to open the filterable operator list panel, with a count of currently filtered operators */
export const FiltersOperatorList = () => {
  const { t } = useTranslation('common')
  const [{ currentFilters }] = useMapFilters()
  const [, { togglePanelVisibility }] = useMapSidebar()

  let filteredOperatorsCount: string
  if (currentFilters[OPERATORS].length === 0) {
    if (currentFilters[ELECTROVERSE_COMPATIBLE]) {
      filteredOperatorsCount = t('map.route_planner.toggles.electroverse_only')
    } else filteredOperatorsCount = t('map.filters.all_operators')
  } else filteredOperatorsCount = currentFilters[OPERATORS].length.toString()

  return (
    <Button
      fullWidth
      variant="outline"
      data-testid="filters-operator-list"
      onClick={() => togglePanelVisibility(SidebarPanels.OPERATOR_LIST)}
      overrideDisabledInteractivity
      className={styles.root}
    >
      <div className={styles.label.root}>
        <h4 className={styles.label.title}>{t('map.operator_list.title')}</h4>

        <p>
          {t('map.filters.selected_operators')}: {filteredOperatorsCount}
        </p>
      </div>

      <ChevronRightIcon className={styles.icon} />
    </Button>
  )
}
