import { tw } from '@electro/shared/utils/tailwind-merge'
import { useMapConfig } from '@electro/consumersite/src/components/Map/hooks'
import { SidebarContainer as Sidebar } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarContainer'
import { SidebarNavLabelsHelpIcon } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarNav'

const styles = {
  root: 'mt-1 xs:ml-1.5 md:mt-4 md:ml-0',
  container: {
    // This root style is shared to a number of components for consistency
    root: {
      all: 'absolute max-w-sm w-full h-[calc(100dvh-6.5rem)] md:h-[calc(100dvh-7rem)]',
      withPadding: 'h-[calc(100dvh-10.5rem)] md:h-[calc(100dvh-12rem)]',
    },
    navLabelsTooltip: tw(
      'w-7 p-1 rounded-lg cursor-help',
      'text-white/70 bg-slate-600/30 hover:bg-slate-600/50 ring-1 ring-white/30',
    ),
    iconNavLabels: tw(
      'text-xl -mt-1.5',
      '[&_p]:border-b [&_p]:border-secondary [&_p]:overflow-hidden [&_p]:m-0 [&_p]:h-11',
    ),
  },
}
export const mapSidebarContainerStyling = styles.container.root

export const MapSidebar = () => {
  const [{ showNavbar }] = useMapConfig()

  return (
    <Sidebar
      className={tw({
        [styles.container.root.all]: true,
        [styles.container.root.withPadding]: showNavbar,
        [styles.root]: true,
      })}
    >
      <Sidebar.IconNav>
        <Sidebar.MapIcon />
        <Sidebar.FiltersIcon />
        <Sidebar.RoutePlannerIcon />
        <Sidebar.MyEVsIcon />
        <Sidebar.FeedbackIcon />
        <Sidebar.KeyIcon />

        <SidebarNavLabelsHelpIcon className={styles.container.navLabelsTooltip} />
      </Sidebar.IconNav>

      {/* VISIBLE */}
      <Sidebar.FiltersPanel />
      <Sidebar.RoutePlannerPanel />
      <Sidebar.MyEVsPanel />
      <Sidebar.FeedbackPanel />
      <Sidebar.KeyPanel />

      {/* HIDDEN */}
      <Sidebar.IconNavLabels />
      <Sidebar.ElectroverseFeatures />
      <Sidebar.OperatorListPanel />
    </Sidebar>
  )
}
