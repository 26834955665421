import useTranslation from 'next-translate/useTranslation'
import { EjAuthorised } from '@electro/consumersite/src/components'
import { Button } from '@electro/shared-ui-components'

const styles = {
  root: 'uppercase hover:text-primary-light tracking-widest shrink-0 focus:ring-0',
}

export const SignUpButton = ({
  fullWidth = false,
  onClick,
}: {
  fullWidth?: boolean
  onClick: () => void
}) => {
  const { t } = useTranslation('common')

  return (
    <EjAuthorised>
      <EjAuthorised.LoggedOut>
        <Button
          size="sm"
          className={styles.root}
          onClick={onClick}
          variant="raised"
          data-testid="sign-up-button"
          fullWidth={fullWidth}
        >
          {t('new_onboarding_signup_button')}
        </Button>
      </EjAuthorised.LoggedOut>
    </EjAuthorised>
  )
}
