export const NoResultsSadnessSvg = ({ className }: { className?: string }) => (
  <svg
    aria-label="No search results"
    className={className}
    width="146"
    height="124"
    viewBox="0 0 146 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42 30.4093C40.2458 30.6072 36.9112 31.1449 35.4164 31.9844L4.89275 49.1295C4.15062 49.5463 3.5803 50.1655 3.26721 50.8944C2.95412 51.6233 2.91506 52.4227 3.15588 53.173L24.0753 118.347C24.2486 118.887 24.561 119.383 24.9878 119.797C25.4145 120.211 25.9439 120.53 26.5339 120.73C27.1238 120.93 27.7582 121.006 28.3866 120.95C29.015 120.894 29.6201 120.709 30.154 120.409L57.8667 104.844C59.7566 103.783 61.8856 103.103 64.1035 102.853C66.3213 102.602 68.574 102.788 70.7024 103.396L102.755 112.551C104.445 113.034 106.233 113.18 107.994 112.979C109.754 112.778 111.443 112.234 112.94 111.388L129.54 102M104.846 30.4093L113.45 25.543C113.984 25.2412 114.59 25.0543 115.219 24.9972C115.849 24.9401 116.484 25.0143 117.076 25.214C117.667 25.4137 118.198 25.7333 118.626 26.1474C119.054 26.5615 119.367 27.0587 119.54 27.5994L140.457 92.7644C140.697 93.5128 140.659 94.3103 140.348 95.038C140.037 95.7656 139.47 96.3845 138.731 96.8025L137 97.7814"
      stroke="currentColor"
      strokeWidth="4.24731"
      strokeMiterlimit="10"
    />
    <circle
      cx="73.3942"
      cy="41.0071"
      r="33.2687"
      transform="rotate(-45.8606 73.3942 41.0071)"
      stroke="currentColor"
      strokeWidth="4.19133"
    />
    <rect
      x="103.944"
      y="76.8578"
      width="9.43049"
      height="38.7698"
      rx="4.71525"
      transform="rotate(-45.8606 103.944 76.8578)"
      stroke="currentColor"
      strokeWidth="4.19133"
    />
    <line
      x1="97.8167"
      y1="63.6116"
      x2="107.216"
      y2="72.7331"
      stroke="currentColor"
      strokeWidth="4.19133"
    />
    <path
      d="M49.4944 42.7467C50.6295 45.5409 56.2529 51.1293 62.3303 45.8902"
      stroke="currentColor"
      strokeWidth="4.19133"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.1664 61.1477C74.0312 58.3535 68.4079 52.7651 62.3304 58.0042"
      stroke="currentColor"
      strokeWidth="3.1435"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.1914 49.0336C82.3266 51.8279 87.9499 57.4163 94.0274 52.1771"
      stroke="currentColor"
      strokeWidth="4.19133"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="116.3" cy="5.76252" r="4.14422" stroke="currentColor" strokeWidth="2.54839" />
    <circle cx="5.03191" cy="7.63361" r="2.03191" fill="currentColor" />
    <circle cx="60.4728" cy="120.677" r="2.25767" fill="currentColor" />
    <circle cx="72.1559" cy="114.053" r="1.58037" fill="currentColor" />
    <circle cx="144.07" cy="27.2104" r="1.58037" fill="currentColor" />
    <path
      d="M14.2616 112.569C14.2616 114.683 12.4578 116.487 10.1174 116.487C7.77695 116.487 5.97317 114.683 5.97317 112.569C5.97317 110.455 7.77695 108.651 10.1174 108.651C12.4578 108.651 14.2616 110.455 14.2616 112.569Z"
      stroke="currentColor"
      strokeWidth="2.54839"
    />
    <path
      d="M26.5599 5.82746C26.5599 6.84263 25.8233 7.48824 25.1249 7.48824C24.4264 7.48824 23.6898 6.84263 23.6898 5.82746C23.6898 4.81229 24.4264 4.16668 25.1249 4.16668C25.8233 4.16668 26.5599 4.81229 26.5599 5.82746Z"
      stroke="currentColor"
      strokeWidth="2.54839"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6292 15.1738C17.6292 14.9752 17.4682 14.8142 17.2697 14.8142C17.0711 14.8142 16.9101 14.9752 16.9101 15.1738V19.3537H12.5844C12.3858 19.3537 12.2249 19.5147 12.2249 19.7133C12.2249 19.9119 12.3858 20.0729 12.5844 20.0729H16.9101V24.4939C16.9101 24.6925 17.0711 24.8535 17.2697 24.8535C17.4682 24.8535 17.6292 24.6925 17.6292 24.4939V20.0729H21.9046C22.1032 20.0729 22.2642 19.9119 22.2642 19.7133C22.2642 19.5147 22.1032 19.3537 21.9046 19.3537H17.6292V15.1738Z"
      fill="currentColor"
    />
    <path
      d="M16.9101 19.3537V20.6279H18.1843V19.3537H16.9101ZM16.9101 20.0729H18.1843V18.7987H16.9101V20.0729ZM17.6292 20.0729V18.7987H16.355V20.0729H17.6292ZM17.6292 19.3537H16.355V20.6279H17.6292V19.3537ZM17.2697 16.0884C16.7645 16.0884 16.355 15.6789 16.355 15.1738H18.9034C18.9034 14.2715 18.172 13.54 17.2697 13.54V16.0884ZM18.1843 15.1738C18.1843 15.6789 17.7748 16.0884 17.2697 16.0884V13.54C16.3673 13.54 15.6359 14.2715 15.6359 15.1738H18.1843ZM18.1843 19.3537V15.1738H15.6359V19.3537H18.1843ZM12.5844 20.6279H16.9101V18.0795H12.5844V20.6279ZM13.499 19.7133C13.499 20.2184 13.0896 20.6279 12.5844 20.6279V18.0795C11.6821 18.0795 10.9507 18.811 10.9507 19.7133H13.499ZM12.5844 18.7987C13.0896 18.7987 13.499 19.2082 13.499 19.7133H10.9507C10.9507 20.6156 11.6821 21.3471 12.5844 21.3471V18.7987ZM16.9101 18.7987H12.5844V21.3471H16.9101V18.7987ZM18.1843 24.4939V20.0729H15.6359V24.4939H18.1843ZM17.2697 23.5793C17.7748 23.5793 18.1843 23.9888 18.1843 24.4939H15.6359C15.6359 25.3962 16.3673 26.1277 17.2697 26.1277V23.5793ZM16.355 24.4939C16.355 23.9888 16.7645 23.5793 17.2697 23.5793V26.1277C18.172 26.1277 18.9034 25.3962 18.9034 24.4939H16.355ZM16.355 20.0729V24.4939H18.9034V20.0729H16.355ZM21.9046 18.7987H17.6292V21.3471H21.9046V18.7987ZM20.99 19.7133C20.99 19.2082 21.3995 18.7987 21.9046 18.7987V21.3471C22.8069 21.3471 23.5384 20.6156 23.5384 19.7133H20.99ZM21.9046 20.6279C21.3995 20.6279 20.99 20.2184 20.99 19.7133H23.5384C23.5384 18.811 22.8069 18.0795 21.9046 18.0795V20.6279ZM17.6292 20.6279H21.9046V18.0795H17.6292V20.6279ZM16.355 15.1738V19.3537H18.9034V15.1738H16.355Z"
      fill="currentColor"
    />
  </svg>
)
