export const TwitterSvg = ({ className }: { className: string }) => (
  <svg
    aria-label="Twitter Icon"
    className={className}
    fill="none"
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="fill-current"
      d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24Zm23.281-4.492.05.83-.839-.102c-3.055-.39-5.724-1.712-7.99-3.932l-1.109-1.101-.285.813c-.604 1.814-.218 3.729 1.04 5.017.672.712.521.813-.637.39-.403-.136-.756-.238-.79-.187-.117.119.286 1.661.605 2.271.437.848 1.326 1.678 2.3 2.17l.823.39-.974.016c-.94 0-.974.017-.873.373.336 1.102 1.662 2.271 3.14 2.78l1.04.356-.906.542a9.452 9.452 0 0 1-4.5 1.254c-.755.017-1.376.085-1.376.136 0 .17 2.048 1.118 3.24 1.491 3.576 1.102 7.823.627 11.013-1.254 2.266-1.339 4.532-4 5.59-6.576.57-1.373 1.141-3.881 1.141-5.084 0-.78.05-.882.99-1.814.555-.542 1.075-1.135 1.176-1.305.168-.322.151-.322-.705-.034-1.427.509-1.629.441-.923-.322.52-.542 1.141-1.525 1.141-1.813 0-.051-.252.034-.537.186-.302.17-.974.424-1.477.577l-.907.288-.823-.56c-.453-.305-1.09-.644-1.426-.745-.857-.238-2.166-.204-2.938.068-2.099.762-3.425 2.728-3.274 4.88Z"
    />
  </svg>
)
