import { Card, Modal, Typography } from '@electro/shared-ui-components'
import { MAP_DEBUG_PARAM } from '@electro/shared/constants'
import { ChargingLocationMetadataType } from '@electro/consumersite/generated/graphql'
import { CodeBracketIcon, LinkIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'

interface LocationDebugInfoProps {
  chargingLocation: ChargingLocationMetadataType
}

const styles = {
  root: '',
}

export const LocationDebugInfo = ({ chargingLocation }: LocationDebugInfoProps) => {
  const router = useRouter()
  const visible = router.query?.[MAP_DEBUG_PARAM] === 'theMoney'
  const [modalOpen, setModalOpen] = useState(false)

  return visible ? (
    <>
      <div className={styles.root} data-testid="location-debug-info-modal">
        <button onClick={() => setModalOpen(true)}>
          <span className="sr-only">Show debug window</span>
          <CodeBracketIcon className="w-6 h-6 text-white" />
        </button>
      </div>
      <Modal size="full" open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.CloseButton />
        <Modal.Header>Location Debug</Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-8">
            <Card>
              <Typography>
                <Link
                  href={`https://api.theelectroverse.com/admin/ejn/location/${chargingLocation.chargingLocationPk}/change/`}
                  className="flex gap-4"
                >
                  <LinkIcon className="w-6 h-6" />
                  https://api.theelectroverse.com/admin/ejn/location/
                  {chargingLocation.chargingLocationPk}/change/
                </Link>
              </Typography>
            </Card>
            <Typography variant="h3">Charging Location raw data</Typography>
            <Card>
              <pre>{JSON.stringify(chargingLocation, null, 2)}</pre>
            </Card>
          </div>
        </Modal.Body>
      </Modal>
    </>
  ) : null
}
