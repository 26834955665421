import {
  ConnectionFee,
  ConsumptionRate,
  ParkingTimeRate,
  TimeRate,
} from '@electro/consumersite/generated/graphql'
import { Locales } from '../types/locales'

export const formatPostcode = (postcode: string): string => {
  const lastMatch = '[0-9][a-zA-Z][a-zA-Z]'
  const match = postcode.match(lastMatch)
  const hasSpace = postcode.indexOf(' ') >= 2

  if (match !== null && !hasSpace) {
    return postcode.replace(/^(.*)(.{3})$/, '$1 $2')
  }

  return postcode
}

export const formatMobileNumber = (mobileNumber: string): string =>
  mobileNumber.replace(/[^0-9]+/g, '')

export const formatAlphaNumeric = (name: string): string => name.replace(/[^a-zA-Z\d\s]/g, '')

export const formatAlphaNumericAllCaps = (name: string): string =>
  formatAlphaNumeric(name).toUpperCase()

/**
 * @param {string, currency } price 4 digit value of price in pence
 * @returns {Record<'pound' | 'decimal' | 'pence', string>}
 */
interface FormatPriceToLocalisedPartsArgs {
  price: number
  currency?: string
  locale?: string
  isFloat?: boolean
}
export const formatPriceToLocalisedValue = ({
  price,
  currency = 'GBP',
  locale = Locales.EN,
  isFloat = false,
}: FormatPriceToLocalisedPartsArgs) => {
  const GBPCurrencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    currencyDisplay: 'narrowSymbol',
  })
  const priceFloat = isFloat ? price : price / 100

  return GBPCurrencyFormatter.format(priceFloat)
}

export const formatNoSpaceChars = (input: string): string => input.replace(/\s/g, '')

export const formatPriceComponentToLocalisedValue = (
  priceComponent: ConsumptionRate | TimeRate | ParkingTimeRate | ConnectionFee,
) => {
  // eslint-disable-next-line no-underscore-dangle
  if (!priceComponent.__typename) return null
  const { unitAmount, currencyDetails } = priceComponent

  if (unitAmount === undefined || unitAmount === null || !currencyDetails) return null
  const { symbol, minorUnitConversion, decimalDigits } = currencyDetails

  const pricePerUnit = (unitAmount / minorUnitConversion).toFixed(decimalDigits ?? 0)
  const perUnitText =
    'perUnit' in priceComponent ? `/${priceComponent?.perUnit?.replace('/', '')}` : ''

  return `${symbol}${pricePerUnit}${perUnitText}`
}

export const formatDistanceToLocalisedValue = ({
  distance,
  locale = Locales.EN,
  unit = 'mile',
  shortUnit = false,
}: {
  distance: number
  locale?: string
  unit: 'mile' | 'kilometer'
  shortUnit?: boolean
}) =>
  new Intl.NumberFormat(locale, {
    style: 'unit',
    unit,
    unitDisplay: shortUnit ? 'short' : 'long',
  }).format(distance)

export const formatDurationToLocalisedValue = ({
  duration,
  locale = Locales.EN,
  unit = 'minute',
}: {
  duration: number
  locale?: string
  unit: 'minute' | 'hour' | 'second' | 'day'
}) => {
  if (!duration) return null
  return new Intl.NumberFormat(locale, {
    style: 'unit',
    unit,
    unitDisplay: 'short',
  }).format(duration)
}

export const formatCompaniesHouseNumber = (houseNumber: string): string =>
  houseNumber.toUpperCase().replace(/[^A-Za-z0-9]/g, '')

export const formatNumbersOnly = (input: string): string => input.replace(/[^0-9]/g, '')
