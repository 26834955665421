export const SOURCE_ATTRIBUTION_CAMPAIGN_ID_PARAM = 'cid'
export const SIGN_UP_REFERRAL_CODE_PARAM = 'referralCode'
export const SIGN_UP_FORM_OE_CUST_FLAG_PARAM = 'oecust'
export const MAP_PITCH_PARAM = 'pitch'
export const MAP_BEARING_PARAM = 'bearing'
export const MAP_ZOOM_PARAM = 'zoom'
export const MAP_LAT_PARAM = 'lat'
export const MAP_LNG_PARAM = 'lng'
export const MAP_ACTIVE_LOCATION_ID = 'activeLocation'
export const MAP_PARTNERED_TOGGLE = 'electroverseOnly'
export const PASSWORD_RESET_TOKEN_PARAM = 'password-reset-token'
export const SHORT_LIVED_LOGIN_TOKEN_PARAM = 'token'
export const SHORT_LIVED_LOGIN_USER_ID_PARAM = 'uid'
export const OAUTH_CALLBACK_CODE_PARAM = 'code'
export const SIGN_UP_VERIFICATION_TOKEN_PARAM = 'verificationToken'
export const SIGN_UP_PASSWORD_REQUIRED_PARAM = 'passwordRequired'
export const SHOW_USER_ONBOARDING_MODAL_TRIGGER_PARAM = 'onboarding'
export const HEADLESS_NAVIGATION_PARAM = 'headless'
export const MAP_DEBUG_PARAM = 'showMe'
