export const FacebookSvg = ({ className }: { className: string }) => (
  <svg
    aria-label="Facebook Icon"
    className={className}
    fill="none"
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24Zm26.502 14.111V25.054h3.604l.478-4.5h-4.082l.006-2.252c0-1.173.111-1.802 1.797-1.802h2.253V12h-3.605c-4.33 0-5.854 2.183-5.854 5.854v2.701H18.4v4.5h2.7V38.11h5.402Z"
      className="fill-current"
    />
  </svg>
)
