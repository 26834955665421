import { useUpdateEffect } from 'react-use'
import useTranslation from 'next-translate/useTranslation'
import { Button, useToastNotification } from '@electro/shared-ui-components'
import { MyEVsVehicleButton } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/MyEVs/components'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { useUserVehicles } from '@electro/consumersite/src/hooks'
import {
  useMapSidebar,
  useRoute,
  useRoutePlannerForm,
} from '@electro/consumersite/src/components/Map/hooks'
import {
  RoutePlannerLocationSearch,
  RoutePlannerChargeSliders,
  RoutePlannerToggles,
} from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/RoutePlanner/components'

const styles = {
  myEVsButton: 'my-1',
  breakpoint: 'border-secondary',
  submitButton: 'mt-auto shrink-0',
}

export const RoutePlannerPanelContent = () => {
  const { t } = useTranslation('common')
  const [{ loading }] = useRoute()
  const [{ activeVehicle }] = useUserVehicles()
  const [, { togglePanelVisibility }] = useMapSidebar()
  const [{ errors }, { submitForm }] = useRoutePlannerForm({})
  const { showToastNotification } = useToastNotification()

  /** Displays route form validation errors when the user tries to submit */
  useUpdateEffect(() => {
    Object.values(errors).forEach((error) => {
      showToastNotification({
        variant: 'error',
        heading: `${t('map.route_planner.button_text')} ${t('common.error')}`,
        body: t(error.toString()),
        timeout: 4000,
      })
    })
  }, [errors, showToastNotification, t])

  return (
    <>
      <MyEVsVehicleButton
        isActive
        icon="arrowsRightLeft"
        vehicle={activeVehicle}
        onClick={() => togglePanelVisibility(SidebarPanels.MY_EVS)}
        className={styles.myEVsButton}
      />

      <hr className={styles.breakpoint} />
      <RoutePlannerLocationSearch />

      <hr className={styles.breakpoint} />
      <RoutePlannerChargeSliders />

      <hr className={styles.breakpoint} />
      <RoutePlannerToggles />

      <Button onClick={submitForm} fullWidth className={styles.submitButton} loading={loading}>
        {t('map.route_planner.button.calculate_route')}
      </Button>
    </>
  )
}
