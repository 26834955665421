export const MailSvg = ({ className }: { className: string }) => (
  <svg
    aria-label="Email Icon"
    className={className}
    fill="none"
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 0C10.745 0 0 10.745 0 24C0 37.255 10.745 48 24 48C37.255 48 48 37.255 48 24C48 10.745 37.255 0 24 0ZM14.5775 16.5H33.3775C34.375 16.5 33.86 17.78 33.3175 18.1075C32.775 18.4325 25.2675 22.975 24.985 23.1425C24.7025 23.31 24.3425 23.3925 23.9775 23.3925C23.6263 23.3997 23.2794 23.3134 22.9725 23.1425L14.6375 18.1075C14.095 17.78 13.5825 16.5 14.5775 16.5ZM34 30.25C34 30.775 33.37 31.5 32.89 31.5H15.11C14.63 31.5 14 30.775 14 30.25V21.1325C14 20.9025 13.995 20.605 14.43 20.8575L22.9725 25.895C23.274 26.0793 23.6248 26.1666 23.9775 26.145C24.3425 26.145 24.6075 26.1175 24.985 25.895L33.57 20.86C34.005 20.605 34 20.905 34 21.135V30.25Z"
      fill="white"
      className="fill-current"
    />
  </svg>
)
