import useTranslation from 'next-translate/useTranslation'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { SidebarPanelContainer } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarPanelContainer'
import { ElectroverseFeaturesPanelContent } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels'

export const ElectroverseFeaturesPanel = () => {
  const { t } = useTranslation('common')

  return (
    <SidebarPanelContainer
      panelType={SidebarPanels.ELECTROVERSE_FEATURES}
      label={t('map.electroverse_features.title')}
    >
      <ElectroverseFeaturesPanelContent />
    </SidebarPanelContainer>
  )
}
