import { getResponsiveBreakpoint } from '@electro/shared/helpers'
import {
  COUNTRY_MAX_ZOOM,
  LOCATION_MAX_ZOOM,
  PADDING,
  SPEED,
} from '@electro/consumersite/src/components/Map/constants'

interface CameraAnimationOptionsProps {
  type?: 'flyTo' | 'fitBounds'
  zoomLevel?: 'location' | 'country'
  isSidebarOpen?: boolean
  isDrawerOpen?: boolean
}

const zoomOptions = {
  location: LOCATION_MAX_ZOOM,
  country: COUNTRY_MAX_ZOOM,
}

/**
 * Adjusts the camera animations depending on a number of useful factors.
 *
 * Note: "When padding is used with jumpTo, easeTo and flyTo, it also sets the
 * global map padding as a side effect, affecting all subsequent camera
 * movements until the padding is reset."
 */
export const getCameraAnimationOptions = ({
  type = 'flyTo',
  zoomLevel = 'location',
  isSidebarOpen = false,
  isDrawerOpen = false,
}: CameraAnimationOptionsProps = {}) => {
  const zoom = zoomOptions[zoomLevel]

  let openUIElements = 'none'
  if (isSidebarOpen && isDrawerOpen) openUIElements = 'sidebarAndDrawer'
  else if (isSidebarOpen) openUIElements = 'sidebarOnly'
  else if (isDrawerOpen) openUIElements = 'drawerOnly'

  return {
    ...(zoom && type === 'flyTo' ? { zoom } : { maxZoom: zoom }),
    padding: PADDING[openUIElements][getResponsiveBreakpoint()],
    speed: SPEED,
  }
}
