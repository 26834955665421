import Link from 'next/link'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Button } from '@electro/shared-ui-components'

const styles = {
  container: 'flex flex-grow flex-col justify-between items-center text-center',
  image: 'px-16 py-4',
  text: {
    root: 'text-center mb-8 override-list-style',
    list: tw(
      'mx-4 !mt-1 pt-2 pb-1 !pl-10 text-left',
      'border-2 border-primary rounded-lg',
      'shadow-primary shadow-md',
    ),
  },
  buttons: tw(
    'w-full flex flex-col gap-y-2',
    '[&_a]:w-full [&_a]:no-underline',
    '[&_svg]:w-5 [&_svg]:ml-2',
  ),
}

export const ElectroverseFeaturesPanelContent = () => {
  const { t } = useTranslation('common')

  return (
    <div className={styles.container}>
      <div>
        <Image
          alt="route planner illustration"
          src="/images/zapman-where-to-next.svg"
          className={styles.image}
          width={407}
          height={356}
        />

        <div className={styles.text.root}>
          <p>{t('map.electroverse_features.unlock_features')}</p>

          <ul className={styles.text.list}>
            <li>
              <p>{t('map.electroverse_features.list.filter_by_vehicle')}</p>
            </li>
            <li>
              <p>{t('map.electroverse_features.list.route_planner')}</p>
            </li>
            <li>
              <p>{t('map.electroverse_features.list.manage_evs')}</p>
            </li>
          </ul>

          <p>{t('map.electroverse_features.tailored_experience')}</p>
        </div>
      </div>

      <div className={styles.buttons}>
        <Link href="/sign-up/magic">
          <Button size="sm" fullWidth variant="raised">
            {t('new_onboarding_signup_button')}
          </Button>
        </Link>

        <Link href="/log-in">
          <Button size="sm" fullWidth>
            {t('user.log_in')}
          </Button>
        </Link>
      </div>
    </div>
  )
}
