import { v1 as uuid } from 'uuid'
import { LineLayerSpecification } from 'mapbox-gl'
import { Marker, Source, Layer } from 'react-map-gl'
import { EjnMarker } from '@electro/consumersite/src/components/Map/types'
import { useMarkers, useRoute } from '@electro/consumersite/src/components/Map/hooks'
import getMarkerIconPath, {
  MarkerNamesEnum,
} from '@electro/consumersite/src/components/Map/helpers/getMarkerIcon'
import getRouteColorFromBatteryPercent from '@electro/shared/utils/getRouteColorFromPercent'

export const RouteLayer = () => {
  const { activeLocationMarker, handleSelectRouteMarker } = useMarkers()
  const [{ routes, activeRouteId }] = useRoute()

  return routes ? (
    <>
      {routes?.data[activeRouteId]?.markers?.map((marker) => {
        const [lng, lat] = marker.coordinates
        const handleMarkerClick = (markerEvt: EjnMarker) => () => {
          handleSelectRouteMarker(markerEvt)
        }

        const isChargePointMarker =
          marker.icon !== MarkerNamesEnum.ROUTE_WAYPOINT &&
          marker.icon !== MarkerNamesEnum.ROUTE_DESTINATION

        return marker.id === activeLocationMarker?.id && isChargePointMarker ? null : (
          <Marker
            // using uuid because in development marker.id is often null as we don't have
            // a matched location in staging. uuid ensures that we don't get any markers
            // without a key. If they have no key they are left on the map when switching
            // between routes.
            key={uuid()}
            longitude={lng}
            latitude={lat}
            anchor={isChargePointMarker ? 'center' : 'bottom'}
            // We only want to trigger the location details panel when a charging location is clicked on.
            onClick={isChargePointMarker ? handleMarkerClick(marker) : null}
          >
            <img src={getMarkerIconPath({ icon: marker.icon })} width={30} alt="route marker" />
          </Marker>
        )
      })}

      {routes?.data[activeRouteId]?.polyLine?.map((line) => {
        const [{ properties }] = line.features
        const lineStyle: LineLayerSpecification = {
          id: properties.id,
          type: 'line',
          slot: 'middle',
          source: properties.id,
          paint: {
            'line-color': 'blue',
            'line-width': 6,
            'line-gradient': [
              'interpolate',
              ['linear'],
              ['line-progress'],
              0.4,
              getRouteColorFromBatteryPercent({
                percent: properties.departureBatteryPerc,
              }).hex,
              1,
              getRouteColorFromBatteryPercent({
                percent: properties.arrivalBatteryPerc,
              }).hex,
            ],
          },
          layout: {
            visibility: activeRouteId ? 'visible' : 'none',
            'line-cap': 'round',
            'line-join': 'round',
          },
        }

        return (
          <Source key={properties.id} id={properties.id} lineMetrics type="geojson" data={line}>
            <Layer {...lineStyle} />
          </Source>
        )
      })}
    </>
  ) : null
}
