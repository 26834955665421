import { Source, Layer } from 'react-map-gl'
import { useMapFilters, useRoute } from '@electro/consumersite/src/components/Map/hooks'
import {
  ejLocationLayerStyle,
  nonEjLocationCircleLayerStyle,
  allLocationsLayerStyle,
} from '@electro/consumersite/src/components/Map/components/MapLayers/layers'

export const LocationsLayer = () => {
  const [{ routes }] = useRoute()
  const [{ elasticSearchParams }] = useMapFilters()

  return !routes ? (
    <Source
      id="charging_locations"
      type="vector"
      tiles={[
        `${process.env.NEXT_PUBLIC_EJN_API_REST_ENDPOINT}locations/tiles/elastic/{z}/{x}/{y}?${elasticSearchParams}`,
      ]}
    >
      <Layer {...nonEjLocationCircleLayerStyle()} />
      <Layer {...allLocationsLayerStyle()} />
      <Layer {...ejLocationLayerStyle()} />
    </Source>
  ) : null
}
