import useTranslation from 'next-translate/useTranslation'
import { useMapFilters } from '@electro/consumersite/src/components/Map/hooks'
import { Button, useToastNotification } from '@electro/shared-ui-components'
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/16/solid'

const styles = {
  root: 'bg-base/10 backdrop-blur absolute left-0 bottom-0 w-full rounded-br-md',
  breakpoint: 'border-secondary',
  buttonGroup: {
    root: 'flex gap-x-2 mx-5',
    button: 'basis-1/2 py-1 my-3',
    icon: 'shrink-0 w-4 h-4 mr-1',
  },
}

/** Various buttons to quickly perform general actions on the filters */
export const FiltersStickyButtons = () => {
  const { t } = useTranslation('common')
  const { showToastNotification } = useToastNotification()
  const [{ filtersSearchParams, filtersActive }, { resetFilters }] = useMapFilters()

  /** Uses a native link share component, otherwise copies it to clipboard */
  const onShareLink = () => {
    const { origin, pathname, hash } = new URL(window.location.href)
    const updatedURL = `${origin}${pathname}${filtersSearchParams.replaceAll(' ', '%20')}${hash}`

    if (navigator.share && navigator.canShare) navigator.share({ url: updatedURL })
    else {
      navigator.clipboard.writeText(updatedURL)
      showToastNotification({
        variant: 'default',
        heading: t('map.location.share_charger.heading'),
        body: t('map.location.share_charger.body'),
        position: 'topRight',
        timeout: 4000,
      })
    }
  }

  return (
    <div className={styles.root}>
      <hr className={styles.breakpoint} />

      <div className={styles.buttonGroup.root}>
        <Button
          onClick={resetFilters}
          disabled={!filtersActive}
          className={styles.buttonGroup.button}
        >
          <ArrowPathIcon className={styles.buttonGroup.icon} />
          {t('map.common.buttons.reset')}
        </Button>

        <Button variant="outline" onClick={onShareLink} className={styles.buttonGroup.button}>
          <ArrowUpOnSquareIcon className={styles.buttonGroup.icon} />
          {t('map.common.buttons.share')}
        </Button>
      </div>
    </div>
  )
}
